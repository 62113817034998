import { APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponent } from './app.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import { AppRoutingModule } from './app-routing.module';
import {
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatSortModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatBadgeModule,
  MatDialogModule,
  MatGridListModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatListModule,
  MatDividerModule
} from '@angular/material';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NavComponent} from "./nav/nav.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import { BatchesComponent } from './batches/batches.component';
import {BatchesService} from "./services/batches.service";
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {InputFileComponent} from "./input-file/input-file.component";
import {PurchaseComponent} from "./purchase/purchase.component";
import {PurchaseService} from "./services/purchase.service";
import {CanActivateApp} from "./app-activate";
import {AuthService} from "./auth/auth.service";
import {AuthInterceptor} from "./core/auth-interceptor";
import {CurrentUserService} from "./core/current-user.service";
import {StorageService} from "./core/storage.service";
import {AuthStateService} from "./core/auth-state.service";
import {LoginComponent} from "./auth/login/login.component";
import { DeleteDialogComponent } from './batches/delete-dialog/delete-dialog.component';
import { NewCertificateComponent } from './new-certificate/new-certificate.component';
import { SuccessDialogComponent } from './new-certificate/success-dialog/success-dialog.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {NewCertificateService} from "./services/new-certificate.service";
import {ProfileService} from './services/profile.service';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
import {BatchResetPasswordComponent} from './auth/reset-password/batch-reset-password.component';
import {BatchPasswordComponent} from './auth/password/batch-password.component';
import {UserProfileComponent} from './profiles/user-profile/user-profile.component';
import {OrganizationProfileComponent} from './profiles/organization-profile/organization-profile.component';
import {OrganizationService} from './services/organization.service';

const materialModules = [
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatSidenavModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatSnackBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatBadgeModule,
  MatDialogModule,
  MatGridListModule,
  MatSlideToggleModule,
  MatSelectModule,
  MatDividerModule,
  MatListModule
];

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    PageNotFoundComponent,
    BatchesComponent,
    InputFileComponent,
    PurchaseComponent,
    LoginComponent,
    DeleteDialogComponent,
    NewCertificateComponent,
    SuccessDialogComponent,
    BatchResetPasswordComponent,
    BatchPasswordComponent,
    UserProfileComponent,
    OrganizationProfileComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FlexLayoutModule,
    AppRoutingModule,
    materialModules,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    StorageService,
    CurrentUserService,
    AuthStateService,
    BatchesService,
    PurchaseService,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    CanActivateApp,
    NewCertificateService,
    ProfileService,
    OrganizationService
  ],
  entryComponents:[ DeleteDialogComponent, SuccessDialogComponent ],
  bootstrap: [AppComponent]
})
export class AppModule { }
