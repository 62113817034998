<div color="primary" class="navbar flex flex-direction-row align-items-center">
  <div class="flex flex-direction-row titles">
    <h1 class="title add-margin" routerLink="/">{{'nav.title' | translate}}</h1>
    <h1 class="for-org" *ngIf="selectedOrganization !== null && !langVisible">{{'nav.for' | translate}}</h1>
  </div>

  <mat-select class="organization" (selectionChange)="changeOrganization($event)" [(ngModel)]="selectedOrganization" *ngIf="!langVisible">
    <mat-option *ngFor="let org of orgs" [value]="org" >{{org.orgName}}</mat-option>
  </mat-select>
  <mat-select class="locale" (selectionChange)="changeLocale()" [(value)]="selectedLanguage" *ngIf="langVisible">
    <mat-select-trigger>
      <span> {{selectedLanguage | uppercase}} </span>
    </mat-select-trigger>
    <mat-option value="en">EN</mat-option>
    <mat-option value="uk">UK</mat-option>
  </mat-select>

  <button mat-icon-button [matMenuTriggerFor]="menu" class="menu" *ngIf="!langVisible">
    <mat-icon>more_vert</mat-icon>
  </button>
</div>

<!-- Mobile Menu -->
<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/batches">
    <mat-icon>assignment</mat-icon>
    <span>{{'nav.menu.batches' | translate}}</span>
  </button>
  <button mat-menu-item routerLink="/purchases">
    <mat-icon>monetization_on</mat-icon>
    <span>{{'nav.menu.purchases' | translate}}</span>
  </button>
  <button mat-menu-item routerLink="/new">
    <mat-icon>note_add</mat-icon>
    <span>{{'nav.menu.manual' | translate}}</span>
  </button>

  <button mat-menu-item routerLink="/profile">
    <mat-icon>account_circle</mat-icon>
    <span>{{'nav.menu.profile' | translate}}</span>
  </button>

  <button mat-menu-item routerLink="/organization">
    <mat-icon>group</mat-icon>
    <span>{{'nav.menu.organization' | translate}}</span>
  </button>

  <button mat-menu-item routerLink="/" (click)="logout()">
    <mat-icon>exit_to_app</mat-icon>
    <span>{{'nav.menu.logout' | translate}}</span>
  </button>
</mat-menu>
