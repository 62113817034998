import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';
import {OrganizationService} from '../../services/organization.service';
import {OrganizationProfileDto} from '../../core/app.interfaces';

@Component({
  selector: 'app-organization-profile',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss']
})
export class OrganizationProfileComponent implements OnInit {
  public orgProfileForm: FormGroup;
  public requestProcessing: boolean;
  public errorMsg: string;
  public isError: boolean;
  public orgProfile: OrganizationProfileDto = {
    orgName: '',
    address1: '',
    address2: '',
    orgType: '',
    description: '',
    contactMail: '',
    contactPhone: '',
    contactPerson: '',
  };

  constructor(private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private organization: OrganizationService) {
    this.orgProfileForm = this.formBuilder.group({
      orgName: [''],
      address1: [''],
      address2: [''],
      orgType: [''],
      description: [''],
      contactMail: [''],
      contactPhone: [''],
      contactPerson: [''],
    });
  }

  ngOnInit() {
    this.readOrgProfile();
    this.isError = false;
  }

  readOrgProfile() {
    this.organization.getOrganization().subscribe((org) => {
      this.orgProfile = org;
    }, (error: HttpErrorResponse) => {
      const errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      this.snackBar.open('Organization profile read error. ' + errorMsg, 'OK', {duration: 3000});
      this.isError = true;
    });
  }

  saveOrganization() {
    if (!this.orgProfileForm.valid) {
      return;
    }
    this.requestProcessing = true;

    this.organization.updateOrganizationProfile({
      orgName: this.orgProfileForm.controls.orgName.value || this.orgProfile.orgName,
      address1: this.orgProfileForm.controls.address1.value || this.orgProfile.address1,
      address2: this.orgProfileForm.controls.address2.value || this.orgProfile.address2,
      orgType: this.orgProfileForm.controls.orgType.value || this.orgProfile.orgType,
      description: this.orgProfileForm.controls.description.value || this.orgProfile.description,
      contactMail: this.orgProfileForm.controls.contactMail.value || this.orgProfile.contactMail,
      contactPhone: this.orgProfileForm.controls.contactPhone.value || this.orgProfile.contactPhone,
      contactPerson: this.orgProfileForm.controls.contactPerson.value || this.orgProfile.contactPerson,
    } as OrganizationProfileDto).subscribe((org) => {
      this.requestProcessing = false;
      this.isError = false;
      this.snackBar.open('Organization information update successful.', 'OK', {duration: 3000});
    }, (error: HttpErrorResponse) => {
      this.extractErrorMessage(error);
      this.isError = true;
      this.requestProcessing = false;
    });
  }

  extractErrorMessage(error: HttpErrorResponse) {
    const msg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
    this.errorMsg = msg['message'] ? msg['message'] : msg;
  }

}
