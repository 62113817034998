import {Component} from '@angular/core';
import {Validators, FormGroup, FormBuilder} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {CurrentUserService} from '../../core/current-user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ChangePasswordDto} from '../../core/app.interfaces';
import {AuthService} from '../auth.service';
import {FormErrorStateMatcher} from '../../core/form-error-state-matcher';

@Component({
  selector: 'batch-password',
  templateUrl: './batch-password.component.html',
  styleUrls: ['./batch-password.component.scss']
})
export class BatchPasswordComponent {
  public requestProcessing: boolean;
  private token: string;
  public errorMsg: string;
  public $isError = false;

  public changePassForm: FormGroup;
  matcher = new FormErrorStateMatcher();

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private currentUserService: CurrentUserService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.changePassForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['']
    }, {validator: this.checkPasswords});
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.params['token'];
    if (!this.token || this.token === '') {
      this.router.navigate(['login']);
    }
    this.authService.getResetPassword({token: this.token}).subscribe((entity) => {

    }, (error) => {
      this.errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      this.$isError = true;
      this.router.navigate(['login']);
    });

  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : {notSame: true};
  }

  onSubmit() {
    if (!this.changePassForm.valid) {
      return;
    }
    this.requestProcessing = true;

    this.authService.postResetPassword({token: this.token}, {
      password: this.changePassForm.controls.password.value,
      confirmPassword: this.changePassForm.controls.confirmPassword.value
    } as ChangePasswordDto).subscribe((result) => {
      this.currentUserService.set(result);
      const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

      if (returnUrl) {
        this.router.navigateByUrl(returnUrl);
      } else {
        this.currentUserService.redirectToDefaultPage();
      }

      this.requestProcessing = false;
    }, (error: HttpErrorResponse) => {
      this.errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      this.$isError = true;
      this.requestProcessing = false;
    });
  }

}

