import {Component, AfterViewInit, ViewChild, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {of as observableOf} from 'rxjs/observable/of';
import {merge} from 'rxjs/observable/merge';
import {catchError} from 'rxjs/operators/catchError';
import {map} from 'rxjs/operators/map';
import {startWith} from 'rxjs/operators/startWith';
import {switchMap} from 'rxjs/operators/switchMap';
import {environment} from '../../environments/environment';
import {PurchaseService} from '../services/purchase.service';
import {IPurchase, IPurchaseTable, IFilterFields} from './purchase';
import {CallbackBatchDto} from "../core/app.interfaces";

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit, AfterViewInit {

  displayedColumns = ['date', 'name', 'email', 'document', 'price', 'paid'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  dataSource = new MatTableDataSource<IPurchase>();
  annUrl = environment.annUrl;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('formFilter') formFilter;
  private formFields: IFilterFields = {batchId: '', dateEnd: '', dateStart: '', email: '', name: ''};
  showBackButton = false;
  callbackToBatches: CallbackBatchDto = {} as any;


  constructor(private purchaseService: PurchaseService,
              private formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private location: Location) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.formFields.batchId = params.batchId || '';
      this.formFilter.value.batchId = params.batchId || '';

      if (params.c_page) {
        this.showBackButton = true;
        this.callbackToBatches.sort = params.c_sort;
        this.callbackToBatches.limit = params.c_limit;
        this.callbackToBatches.order = params.c_order;
        this.callbackToBatches.page = params.c_page;
      }
      this.location.replaceState('/purchases');
    });

  }

  ngAfterViewInit() {
    this.formFields = this.formFilter.value as IFilterFields;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.purchaseService.getPurchase(this.sort.active, this.sort.direction, this.paginator.pageSize, this.paginator.pageIndex, this.formFields);
        }),
        map((data: IPurchaseTable) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total_count;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: IPurchase[]) => {

      this.dataSource.data = data;
    });
  }

  applyFilter(form: any) {
    this.formFields = this.formFilter.value as IFilterFields;
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.purchaseService.getPurchase(this.sort.active, this.sort.direction, this.paginator.pageSize, this.paginator.pageIndex, form);
        }),
        map((data: IPurchaseTable) => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total_count;
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: IPurchase[]) => {
      this.dataSource.data = data;
    });
  }

  resetFilter() {
    this.formFilter.resetForm();
    this.formFields = this.formFilter.value as IFilterFields;
  }
}
