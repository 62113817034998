import { OnInit } from '@angular/core';
import { CurrentUserService } from "../core/current-user.service";
import { AuthService } from "../auth/auth.service";
import { TranslateService } from '@ngx-translate/core';
import { OrganizationService } from "../services/organization.service";
import { Router } from "@angular/router";
import * as _ from 'lodash';
var NavComponent = /** @class */ (function () {
    function NavComponent(authService, currentUserService, translate, organizationService, router) {
        var _this = this;
        this.authService = authService;
        this.currentUserService = currentUserService;
        this.translate = translate;
        this.organizationService = organizationService;
        this.router = router;
        this.selectedLanguage = 'en';
        this.selectedOrganization = null;
        this.orgs = [];
        this.langVisible = true;
        this.currentUser = null;
        translate.onLangChange.subscribe(function (event) {
            _this.selectedLanguage = event.lang;
        });
        router.events.subscribe(function (url) {
            if (url['url']) {
                var firstChildren = url['url'].split('?')[0];
                _this.langVisible = (firstChildren === '/login' || firstChildren === '/');
            }
        });
        this.authService.currentUser.subscribe(function (user) {
            _this.currentUser = user.user;
            localStorage.setItem('locale', user.locale);
            _this.loadPars();
        });
    }
    NavComponent.prototype.ngOnInit = function () {
        if (this.currentUserService.get()) {
            this.loadPars();
        }
    };
    NavComponent.prototype.loadPars = function () {
        var _this = this;
        this.organizationService.getOrganizations().subscribe(function (value) {
            _this.orgs = value;
            _this.currentUser = _this.currentUserService.get();
            _this.selectedOrganization = (!!value && value.length > 0) ? _.find(_this.orgs, { id: _this.currentUser.org }) : null;
        }, function (error) {
            // TODO: handle error
        });
    };
    NavComponent.prototype.changeLocale = function () {
        this.translate.use(this.selectedLanguage);
    };
    NavComponent.prototype.changeOrganization = function ($event) {
        var _this = this;
        this.selectedOrganization = $event.value;
        this.organizationService.changeOrg(this.selectedOrganization.id).subscribe(function (value) {
            _this.currentUserService.set(value);
            window.location.reload();
        }, function (error) {
            console.error(error);
        });
    };
    NavComponent.prototype.logout = function () {
        var _this = this;
        this.authService.logout().subscribe(function (result) {
            _this.currentUserService.logout();
        });
    };
    return NavComponent;
}());
export { NavComponent };
