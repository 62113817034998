<div class="containers">
  <div class="templates-loading-shade" *ngIf="isLoadingResults">
    <div style="margin-bottom: 40%">
    <mat-spinner *ngIf="isLoadingResults" [diameter]="100"></mat-spinner>
    </div>
  </div>
    <mat-grid-list cols="1" rowHeight="70px">
      <mat-grid-tile>
        <mat-form-field class="title-template">
          <mat-select placeholder="{{'manual.select-placeholder' | translate}}" required>
            <mat-option *ngFor="let template of templates" [value]="template._id" (click)="onChoseTemplate(template)">
              {{template.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>

    <div style="margin: 0 10px 0">
      <div class="fields-loading-shade" *ngIf="isLoadingFields">
        <div style="margin-bottom: 40%">
          <mat-spinner *ngIf="isLoadingFields" [diameter]="100"></mat-spinner>
        </div>
      </div>
      <div class="success-loading-shade" *ngIf="isLoadingResponse">
        <div style="margin-bottom: 40%">
          <mat-spinner *ngIf="isLoadingResponse" [diameter]="100"></mat-spinner>
        </div>
      </div>
      <form [formGroup]="addPurchaseForm" (ngSubmit)="newBatch(addPurchaseForm.value)" *ngIf="show">
        <mat-grid-list cols="2" rowHeight="70px" *ngIf="fields.length > 0">
          <mat-grid-tile>
            <mat-form-field>
              <input matInput placeholder="{{'manual.fields.sname' | translate}}" formControlName="sName" required>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field>
              <input matInput placeholder="{{'manual.fields.email-address' | translate}}" formControlName="eMail" required>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-slide-toggle color="primary" labelPosition="after" (toggleChange)="sLockMail = !sLockMail" class="slider-toggle" formControlName="sLockMail">
              <p>{{'manual.fields.email-toggle' | translate}}</p>
            </mat-slide-toggle>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field>
              <mat-select placeholder="{{'manual.fields.language' | translate}}" [value]="defaultLanguage" formControlName="sLang">
                <mat-option value="uk">
                  Українська
                </mat-option>
                <mat-option value="en">
                  English
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field>
              <mat-select placeholder="{{'manual.fields.currency.placeholder' | translate}}" [value]="defaultCurrency">
                <mat-option value="usd">
                  {{'manual.fields.currency.usd' | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-grid-tile>

          <mat-grid-tile>
            <mat-form-field>
              <mat-label>{{'manual.fields.price.label' | translate}}</mat-label>
              <input matInput placeholder="{{'manual.fields.price.placeholder' | translate}}" type="number" formControlName="price">
            </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="70px">
            <!--Variable inputs-->
          <mat-grid-tile *ngFor="let field of fields">
            <mat-form-field>
              <input matInput [placeholder]="field.name" [formControlName]="field.name" required>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
        <fieldset class="protected-box">
          <legend class="legend-box">{{'manual.fields.box-label' | translate}}</legend>
          <mat-grid-list cols="2" rowHeight="70px">
            <mat-grid-tile [colspan]="1" [rowspan]="1">
              <mat-form-field>
                <input matInput placeholder="{{'manual.fields.document-name' | translate}}" formControlName="name">
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="3">
              <mat-form-field appearance="outline">
                <mat-label>{{'manual.fields.criteria' | translate}}</mat-label>
                <textarea mat-autosize cdkAutosizeMinRows="8" cdkAutosizeMaxRows="8" matInput  formControlName="criteria"></textarea>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="1">
              <mat-form-field>
                <mat-label>{{'manual.fields.issue-date' | translate}}</mat-label>
                <input matInput [matDatepicker]="dp1" placeholder="YYYY-MM-DD" formControlName="issuedAt">
                <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                <mat-datepicker #dp1
                                startView="multi-year"
                                panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile [colspan]="1" [rowspan]="1">
              <mat-form-field>
                <mat-label>{{'manual.fields.expire-date' | translate}}</mat-label>
                <input matInput [matDatepicker]="dp2" placeholder="YYYY-MM-DD" formControlName="expiresAt">
                <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                <mat-datepicker #dp2
                                startView="multi-year"
                                panelClass="example-month-picker">
                </mat-datepicker>
              </mat-form-field>
            </mat-grid-tile>
          </mat-grid-list>
        </fieldset>
      <div class="button-fix">
        <button mat-raised-button color="primary" type="submit" >{{'manual.fields.generate-button' | translate}}</button>
      </div>
      </form>
    </div>
</div>
