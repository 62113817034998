import {Component, AfterViewInit, ViewChild, OnInit, ElementRef} from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MatAutocompleteTrigger, MatDialog, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Observable} from 'rxjs/Observable';
import {merge} from 'rxjs/observable/merge';
import {of as observableOf} from 'rxjs/observable/of';
import {catchError} from 'rxjs/operators/catchError';
import {map} from 'rxjs/operators/map';
import {startWith} from 'rxjs/operators/startWith';
import {switchMap} from 'rxjs/operators/switchMap';
import {BatchesService} from '../services/batches.service';
import {IBatch, IBatchesState, IBatchesTable, IBatchSmall} from './batches';
import {FileValidators} from '../input-file/file-validators';
import 'rxjs/add/operator/toPromise';
import {saveAs} from 'file-saver';
import {CurrentUserService} from "../core/current-user.service";
import { TemplateDto } from "../core/app.interfaces";
import * as _ from 'lodash';
import {DeleteDialogComponent} from "./delete-dialog/delete-dialog.component";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';

const observedTimeout = 2000;

@Component({
  selector: 'app-batches',
  templateUrl: './batches.component.html',
  styleUrls: ['./batches.component.scss']
})

export class BatchesComponent implements OnInit, AfterViewInit {
  addBatchForm: FormGroup;
  displayedColumns = ['registration', 'eMail', 'templateId', 'iCount', 'rCount', 'eCount', 'state', 'pCount', 'delete'];
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  dataSource = new MatTableDataSource();
  batchFile: File;
  observedBatches = [];
  disabled = true;
  templates = [];
  selectedTemplate: TemplateDto = null;
  show = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('form') myNgForm;
  @ViewChild(MatAutocompleteTrigger) trigger;

  c_sort: string = 'registration';
  c_order: string = 'desc';
  pageSize: number = 10;

  constructor(private batchesService: BatchesService,
              private formBuilder: FormBuilder,
              private currentUserService: CurrentUserService,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private location: Location, private translate: TranslateService ) {
    this.addBatchForm = this.formBuilder.group({
      'templateId': [{value:'', disabled: true}, [Validators.required]],
      'eMail': ['', [Validators.required, Validators.email]],
      'fileInput': [{
        value: undefined,
        disabled: false
      }, [Validators.required, FileValidators.maxContentSize(100 * 1024 * 1024)]]
    });

    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      translate.get('batches.form.template.file-defined').subscribe((value) => {
        this.selectedTemplate === null ? this.addBatchForm.patchValue({templateId: value}) : null;
      });

      // TODO: add paginator labels translation on air
      // this.translate.get('batches.table.paginator.items-per-page-label').subscribe((res: string) => {
      //   this.paginator._intl.itemsPerPageLabel=res;
      // });
      // this.translate.get('batches.table.paginator.next-page-label').subscribe((res: string) => {
      //   this.paginator._intl.nextPageLabel=res;
      // });
      // this.translate.get('batches.table.paginator.previous-page-label').subscribe((res: string) => {
      //   this.paginator._intl.previousPageLabel=res;
      // });
    });
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      if (params.sort) {
        this.c_sort = params.sort;
        this.c_order = params.order;
        this.pageSize = params.limit;
        this.paginator.pageIndex = params.page;
      }
    });
    this.addBatchForm.patchValue({eMail: this.currentUserService.get().email});
    this.batchesService.getTemplateList().subscribe((list: {templates: TemplateDto[]}) => {
      this.templates = list.templates;
    });
    this.location.replaceState('/batches');
  }
  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.c_sort = this.sort.active;
      this.c_order = this.sort.direction;
    });

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.batchesService!.getBatches(
            (this.c_sort === 'delete' ? 'pCount' : this.c_sort), this.c_order, this.paginator.pageSize, this.paginator.pageIndex);
        }),
        map((data: IBatchesTable) => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.total_count;
          data.items.filter(b => b.state > 0 && b.state < 100).forEach(item => this.observedBatches.push(item.id));
          return data.items;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe((data: MatTableDataSource<IBatch>[]) => {
      // console.log(data);
      this.dataSource.data = data;
    });

    setTimeout(() => this.updateObserved(), observedTimeout);
  }

  addBatch(batch: IBatch) {
    // batch.file=this.batchFile;
    // delete batch.fileInput;
    if (this.selectedTemplate) {
      batch.templateId = this.selectedTemplate._id;
    }
    // console.log(this.addBatchForm);
    // batch.eMail === "" ? this.addBatchForm.eMail.value

    this.batchesService.addBatchMultipart(batch, this.batchFile).subscribe((newBatch) => {
      this.addBatchForm.controls['fileInput'].reset();
      this.observedBatches.push(newBatch.id);
      this.ngAfterViewInit();      // this.dataSource.data.push(newBatch); this.resultsLength++;
    }, (response: Response) => {
      if (response.status === 500) {
        console.log('addBatch: errorHasOcurred');
      }
    });
  }

  updateObserved() {
    if (this.observedBatches.length) {
      this.batchesService.getObserved(this.observedBatches).subscribe((batches: IBatchesState[]) => {
        batches.forEach(item => {
          if (item.state < 0 || item.state >= 100) {
            let i = this.observedBatches.indexOf(item.id, 0);
            if (i > -1) {
              this.observedBatches.splice(i, 1);
              this.batchesService.showSnackBar('Batch processing finished');
            }
          }
          this.dataSource.data.filter((b: IBatch) => b.id === item.id).forEach((b: IBatch) => {
            b.state = item.state;
            b.stateUpdated = item.stateUpdated;
            b.errorText = item.errorText;
            b.pCount = item.pCount;
            b.rCount = item.rCount;
            b.eCount = item.eCount;
          });
        });
        setTimeout(() => this.updateObserved(), observedTimeout);
      }, (response: Response) => {
        if (response.status === 500) {
          console.log('updateObserved: errorHasOcurred');
        }
      });
    } else {
      setTimeout(() => this.updateObserved(), observedTimeout);
    }
  }

  openFile(event) {
    let files = event.target.files;
    this.batchFile = (files && files.length) ? files[0] : undefined;
  }

  isLoaded(): boolean {
    return this.batchFile !== undefined;
  }

  downloadFile(id: string, params: string) {
    this.batchesService.downloadFile(id, params);
  }

  pushBatch(id: string) {
    this.batchesService.pushBatch(id);
    this.observedBatches.push(id);
    this.ngAfterViewInit();      // this.dataSource.data.push(newBatch); this.resultsLength++;
  }

  pushVisible(row: IBatch): boolean {
    let now = new Date();
    let diff = (now.getTime() - (new Date(row.stateUpdated).getTime())) / 1000.0;
    return row.state < 0 || ((row.state < 100 || row.errorText.length > 0) && diff > 30.0);
  }

  onFileDefined(option?: TemplateDto){
    if (option) {
      this.disabled = false;
      this.addBatchForm.patchValue({templateId: option.name});
      this.selectedTemplate = option;
    } else {
      this.disabled = true;
      this.selectedTemplate = null;
      this.addBatchForm.controls['templateId'].disable();
      this.translate.get('batches.form.template.file-defined').subscribe((value) => {
        this.addBatchForm.patchValue({templateId: value});
      });
    }
  }

  onManual(){
    this.addBatchForm.controls['templateId'].setValue('');
    this.addBatchForm.controls['templateId'].enable();
  }

  deleteBatch(batch: IBatchSmall) {
    this.batchesService.deleteBatch(batch.id).subscribe((result)=>{
      this.dataSource.data = _.remove(this.dataSource.data, (value: IBatchSmall) => {
        return value.id != batch.id;
      });
      this.batchesService.showSnackBar('Batch has been deleted');
    }, (error) => {
      this.batchesService.showSnackBar(error.error.message);
    });
  }

  openDeleteDialog(batch: IBatchSmall){
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '250px',
      autoFocus: false
    }).afterClosed().subscribe( result => {
      result ? this.deleteBatch(batch) : null;
    });
  }

}
