<div id="left">
  <mat-card class="">
    <!-- Header and description <mat-card-title>Batches</mat-card-title> -->
    <div class="container">
      <div *ngIf="showBackButton" class="fix-back-button" [routerLink]="['/batches']" [queryParams]="{sort: callbackToBatches.sort, order: callbackToBatches.order, limit: callbackToBatches.limit, page: callbackToBatches.page}">
        <button mat-icon-button>
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <mat-card-title class="fix-back-button">{{'purchases.title' | translate}}</mat-card-title>
    </div>

    <form #formFilter="ngForm" (ngSubmit)="applyFilter(formFilter.value)" novalidate>
      <div fxLayout="row">
        <div>
          <mat-form-field class="example-form-field">
            <input matInput type="text" placeholder="{{'purchases.filter.name' | translate}}" name="name" ngModel #uname/>
          </mat-form-field>
        </div>
        <div class="margin">
          <mat-form-field class="example-form-field">
            <input matInput type="text" placeholder="{{'purchases.filter.email' | translate}}" name="email" ngModel #uemail/>
          </mat-form-field>
        </div>

        <!--Filter by date-->
        <div class="margin">
          <mat-form-field>
            <input matInput [matDatepicker]="pickerStart" placeholder="{{'purchases.filter.from-time' | translate}}" name="dateStart" ngModel>
            <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" #pickerStart></mat-datepicker>
          </mat-form-field>
        </div>

        <div class="margin">
          <mat-form-field>
            <input matInput [matDatepicker]="pickerEnd" placeholder="{{'purchases.filter.to-time' | translate}}" name="dateEnd" ngModel>
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker touchUi="true" #pickerEnd></mat-datepicker>
          </mat-form-field>
        </div>

        <!--Filter by batch id-->
        <div class="margin">
          <mat-form-field>
            <input matInput type="text" placeholder="{{'purchases.filter.batch-id' | translate}}" name="batchId" ngModel #batchid/>
          </mat-form-field>
        </div>

        <div class="tops">
          <button mat-raised-button type="submit" class="btn btn-primary margin">{{'purchases.filter.apply-button' | translate}}</button>
        </div>
        <div class="tops">
          <button mat-raised-button (click)="resetFilter()" class="btn btn-primary margin">{{'purchases.filter.reset-button' | translate}}</button>
        </div>
      </div>
    </form>
    <!-- Table -->
    <mat-card-content>
      <div class="batches-container">
        <div class="batches-loading-shade" *ngIf="isLoadingResults||isRateLimitReached">
          <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
          <div class="batches-rate-limit-reached" *ngIf="isRateLimitReached">
            REST API rate limit has been reached. It will be reset in one minute.
          </div>
        </div>
        <mat-table #table [dataSource]="dataSource"
                   matSort matSortDisableClear="true" matSortActive="date" matSortDirection="desc">

          <!--Student Name  Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchases.table.student-name' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.name}}</mat-cell>
          </ng-container>

          <!--Price  Column -->
          <ng-container matColumnDef="price">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchases.table.price' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.price}}</mat-cell>
          </ng-container>

          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchases.table.date' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.date | date:'yyyy-MM-dd HH:mm a'}}</mat-cell>
          </ng-container>

          <!--Email  Column -->
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchases.table.email' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.email}}</mat-cell>
          </ng-container>

          <!--Document  Column -->
          <ng-container matColumnDef="document">
            <mat-header-cell #calldocument *matHeaderCellDef mat-sort-header>{{'purchases.table.document' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element">
              <a target="_blank" rel="noopener noreferrer" style="max-width: 100%" href="{{annUrl}}/certificates/{{element.document}}">{{element.document}}</a>

            </mat-cell>
            <mat-cell *matCellDef="let element">{{element.document}}</mat-cell>
          </ng-container>

          <!--Paid  Column -->
          <ng-container matColumnDef="paid">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{'purchases.table.paid' | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.paid | date:'yyyy-MM-dd HH:mm a'}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator #paginator
                       [length]="resultsLength"
                       [pageSize]="10"
                       [pageSizeOptions]="[5, 10, 25, 50, 100]">
        </mat-paginator>
      </div>

    </mat-card-content>
  </mat-card>
</div>
