/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./success-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i7 from "@angular/material/button";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "@angular/platform-browser/animations";
import * as i11 from "./success-dialog.component";
var styles_SuccessDialogComponent = [i0.styles];
var RenderType_SuccessDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SuccessDialogComponent, data: {} });
export { RenderType_SuccessDialogComponent as RenderType_SuccessDialogComponent };
export function View_SuccessDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "mat-dialog-content", [["class", "title-box mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h1", [["style", "margin: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "link-margin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "link-margin"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(12, 1), (_l()(), i1.ɵted(13, null, ["", ""])), i1.ɵpid(131072, i3.TranslatePipe, [i3.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(15, 0, null, null, 5, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 3, "button", [["color", "primary"], ["mat-dialog-close", ""], ["mat-raised-button", ""], ["type", "button"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null], [1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dialogRef.close(i1.ɵnov(_v, 19).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatButton_0, i6.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i7.MatButton, [i1.ElementRef, i8.Platform, i9.FocusMonitor, [2, i10.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(19, 606208, null, 0, i2.MatDialogClose, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i1.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { var currVal_5 = _ck(_v, 12, 0, "/purchases"); _ck(_v, 11, 0, currVal_5); var currVal_10 = "primary"; _ck(_v, 18, 0, currVal_10); var currVal_11 = ""; _ck(_v, 19, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform("manual.success-dialog.message")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.data.certificate, ""); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform("manual.success-dialog.view-link")); _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵnov(_v, 11).target; var currVal_4 = i1.ɵnov(_v, 11).href; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_6 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("manual.success-dialog.purchase-link")); _ck(_v, 13, 0, currVal_6); var currVal_7 = (i1.ɵnov(_v, 18).disabled || null); var currVal_8 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); var currVal_9 = (i1.ɵnov(_v, 19).ariaLabel || null); _ck(_v, 17, 0, currVal_7, currVal_8, currVal_9); }); }
export function View_SuccessDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-success-dialog", [], null, null, null, View_SuccessDialogComponent_0, RenderType_SuccessDialogComponent)), i1.ɵdid(1, 114688, null, 0, i11.SuccessDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuccessDialogComponentNgFactory = i1.ɵccf("app-success-dialog", i11.SuccessDialogComponent, View_SuccessDialogComponent_Host_0, {}, {}, []);
export { SuccessDialogComponentNgFactory as SuccessDialogComponentNgFactory };
