import { Subject } from 'rxjs/Subject';
import { OnInit, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { FileInput } from './file-input.model';
var InputFileComponent = /** @class */ (function () {
    /**
     * @see https://angular.io/api/forms/ControlValueAccessor
     */
    function InputFileComponent(ngControl, fm, _elementRef, _renderer) {
        var _this = this;
        this.ngControl = ngControl;
        this.fm = fm;
        this._elementRef = _elementRef;
        this._renderer = _renderer;
        this.stateChanges = new Subject();
        this.focused = false;
        this.controlType = 'file-input';
        this._required = false;
        this.id = "app-input-file-" + InputFileComponent.nextId++;
        this.describedBy = '';
        this._onChange = function (_) { };
        this._onTouched = function () { };
        ngControl.valueAccessor = this;
        fm.monitor(_elementRef.nativeElement, true).subscribe(function (origin) {
            _this.focused = !!origin;
            _this.stateChanges.next();
        });
    }
    Object.defineProperty(InputFileComponent.prototype, "value", {
        get: function () {
            return this.empty ? null : new FileInput(this._elementRef.nativeElement.value || []);
        },
        set: function (fileInput) {
            this.writeValue(fileInput.files);
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "placeholder", {
        get: function () {
            return this._placeholder;
        },
        set: function (plh) {
            this._placeholder = plh;
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "empty", {
        get: function () {
            return !this._elementRef.nativeElement.value || this._elementRef.nativeElement.value.length === 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "shouldPlaceholderFloat", {
        get: function () {
            return this.focused || !this.empty || this.valuePlaceholder !== undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "required", {
        get: function () {
            return this._required;
        },
        set: function (req) {
            this._required = coerceBooleanProperty(req);
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "isDisabled", {
        get: function () {
            return this.disabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "disabled", {
        get: function () {
            return this._elementRef.nativeElement.disabled;
        },
        set: function (dis) {
            this.setDisabledState(coerceBooleanProperty(dis));
            this.stateChanges.next();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InputFileComponent.prototype, "errorState", {
        get: function () {
            return this.ngControl.errors !== null && this.ngControl.touched;
        },
        enumerable: true,
        configurable: true
    });
    InputFileComponent.prototype.setDescribedByIds = function (ids) {
        this.describedBy = ids.join(' ');
    };
    InputFileComponent.prototype.onContainerClick = function (event) {
        if (event.target.tagName.toLowerCase() !== 'input' && !this.disabled) {
            this._elementRef.nativeElement.querySelector('input').focus();
            this.focused = true;
            this.open();
        }
    };
    InputFileComponent.prototype.writeValue = function (obj) {
        this._renderer.setProperty(this._elementRef.nativeElement, 'value', obj);
    };
    InputFileComponent.prototype.registerOnChange = function (fn) {
        this._onChange = fn;
    };
    InputFileComponent.prototype.registerOnTouched = function (fn) {
        this._onTouched = fn;
    };
    InputFileComponent.prototype.change = function (event) {
        var fileList = event.target.files;
        var fileArray = [];
        if (fileList) {
            for (var i = 0; i < fileList.length; i++) {
                fileArray.push(fileList[i]);
            }
        }
        this.value = new FileInput(fileArray);
        this._onChange(this.value);
    };
    InputFileComponent.prototype.blur = function () {
        this.focused = false;
        this._onTouched();
    };
    InputFileComponent.prototype.setDisabledState = function (isDisabled) {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    };
    InputFileComponent.prototype.ngOnInit = function () {
        this.multiple = coerceBooleanProperty(this.multiple);
    };
    InputFileComponent.prototype.open = function () {
        if (!this.disabled) {
            this._elementRef.nativeElement.querySelector('input').click();
        }
    };
    Object.defineProperty(InputFileComponent.prototype, "fileNames", {
        get: function () {
            return this.value ? this.value.fileNames : this.valuePlaceholder;
        },
        enumerable: true,
        configurable: true
    });
    InputFileComponent.prototype.ngOnDestroy = function () {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this._elementRef.nativeElement);
    };
    InputFileComponent.nextId = 0;
    return InputFileComponent;
}());
export { InputFileComponent };
