import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var NewCertificateService = /** @class */ (function () {
    function NewCertificateService(http) {
        this.http = http;
    }
    NewCertificateService.prototype.newPurchase = function (form) {
        return this.http.post(environment.apiUrl + "/batches/generate", JSON.stringify(form), httpOptions);
    };
    NewCertificateService.prototype.getTemplate = function (id) {
        return this.http.get(environment.apiUrl + "/templates/get/" + id);
    };
    return NewCertificateService;
}());
export { NewCertificateService };
