import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { saveAs } from 'file-saver';
import { MatSnackBar } from '@angular/material';
import { environment } from "../../environments/environment";
import { throwError } from 'rxjs';
var BatchesService = /** @class */ (function () {
    function BatchesService(http, snackBar) {
        this.http = http;
        this.snackBar = snackBar;
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
    }
    BatchesService.prototype.handleError = function (error, display) {
        if (display === void 0) { display = false; }
        console.log(error);
        if (display)
            this.showSnackBar('Error');
        if (error instanceof Response) {
            return throwError(error.json()['error'] || 'backend server error');
        }
        return throwError(error || 'backend server error');
    };
    BatchesService.prototype.ngOnInit = function () {
    };
    BatchesService.prototype.getTable = function (table, sort, order, limit, page) {
        return this.http.get(environment.apiUrl + "/table/" + table + "/?sort=" + sort + "&order=" + order + "&skip=" + (page + 1) + "&limit=" + limit);
    };
    BatchesService.prototype.getBatches = function (sort, order, limit, page) {
        return this.http.get(environment.apiUrl + "/batches/all/?sort=" + sort + "&order=" + order + "&skip=" + limit * page + "&take=" + limit);
    };
    BatchesService.prototype.addBatch = function (batch) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/batches/add", JSON.stringify(batch), { headers: this.headers, reportProgress: true, withCredentials: false })
            .map(function (response) {
            _this.showSnackBar('Batch processing started');
            return response;
        })
            .catch(function (error) { return _this.handleError(error, true); });
    };
    BatchesService.prototype.addBatchMultipart = function (batch, file) {
        var _this = this;
        var headers = new HttpHeaders({ 'Content-Type': 'undefined' });
        var params = new HttpParams();
        var options = {
            params: params,
            reportProgress: true,
        };
        var route = file.name.toLowerCase().endsWith(".zip") ? 'addmedia' : 'addbatch'; //Different route for *.zip (batch.json+attachments) and  (*.json or *.* as *.csv)
        var fd = new FormData();
        fd.append('eMail', batch.eMail);
        fd.append('templateId', batch.templateId);
        fd.append('file', file, file.name);
        return this.http
            .post(environment.apiUrl + "/batches/" + route, fd, options)
            //{headers: headers,reportProgress:true,withCredentials:false})
            .map(function (response) {
            _this.showSnackBar('Batch processing started');
            return response;
        })
            .catch(function (error) { return _this.handleError(error, true); });
    };
    BatchesService.prototype.pushBatch = function (id) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/batches/push", JSON.stringify({ id: id }), { headers: this.headers, reportProgress: true, withCredentials: false })
            .toPromise()
            .then(function (response) {
            _this.showSnackBar('Batch processing started');
            return response;
        })
            .catch(function (error) { return _this.handleError(error, true); });
    };
    BatchesService.prototype.getObserved = function (batches) {
        var _this = this;
        return this.http
            .post(environment.apiUrl + "/batches/observed", JSON.stringify({ id: batches }), { headers: this.headers, reportProgress: true, withCredentials: false })
            .map(function (response) {
            return response;
        })
            .catch(function (error) { return _this.handleError(error, true); });
    };
    BatchesService.prototype.downloadFile = function (id, type) {
        var _this = this;
        var headers = new HttpHeaders({ 'Accept': 'application/json' });
        return this.http.get(environment.apiUrl + "/batches/result/" + id + '?type=' + (type || ''), { headers: headers })
            .toPromise()
            .then(function (response) {
            var blob = new Blob([response['data']], { type: 'text/plain' });
            saveAs(blob, response['name']);
        })
            .catch(function (error) { return _this.handleError(error, true); });
    };
    BatchesService.prototype.showSnackBar = function (name) {
        this.snackBar.open(name, 'OK', { duration: 3000 });
    };
    BatchesService.prototype.getTemplateList = function () {
        return this.http.get(environment.apiUrl + "/templates/all");
    };
    BatchesService.prototype.deleteBatch = function (batchId) {
        return this.http.delete(environment.apiUrl + "/batches/delete/" + batchId);
    };
    return BatchesService;
}());
export { BatchesService };
