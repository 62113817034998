import {Component, ElementRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {FormControl, NgForm, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatSnackBar} from '@angular/material';

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'batch-reset-password',
  templateUrl: './batch-reset-password.component.html',
  styleUrls: ['./batch-reset-password.component.scss'],
})
export class BatchResetPasswordComponent {
  public requestProcessing: boolean;
  public $isError = false;
  public entity: any = {};
  public errorMsg: string;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(EMAIL_REGEX)]);
  @ViewChild('editForm') public editForm: NgForm;
  @ViewChild('errorInfo') errorLabel: ElementRef;

  constructor(private authService: AuthService,
              private _router: Router,
              private snackBar: MatSnackBar) {
  }

  submit() {
    this.$isError = false;
    if (!this.editForm.valid) {
      return;
    }

    this.requestProcessing = true;
    this.authService.forgotPassword({
      email: this.entity.email,
      app: this.getCurrentURL()
    }).subscribe((result) => {
      this.requestProcessing = false;
      this.snackBar.open('An e-mail has been sent to ' + this.entity.email + ' with further instructions.', 'OK', {duration: 5000});
      this.cancel();
    }, (error: HttpErrorResponse) => {
      this.errorMsg = error.status === 401 ? error.error['message'] : 'Something wrong.';
      this.$isError = true;
      this.requestProcessing = false;
      this._router.navigate(['reset-password']);
    }, () => {
      this.requestProcessing = false;
    });
  }

  getCurrentURL(): string {
    return window.location.origin;
  }

  cancel() {
    this._router.navigate(['login']);
  }
}
