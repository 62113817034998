/// <reference types="node" />
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import 'zone.js/dist/zone';

const ENV_URL = '/assets/config/environment.json';

if (environment.production) {
  enableProdMode();
}

fetch(ENV_URL).then(async res => {
  Object.assign(environment, await res.json());

  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
});
