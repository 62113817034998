import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ProfileService} from "./services/profile.service";
import {AuthService} from "./auth/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

    constructor(private translate: TranslateService, private profileService: ProfileService, private authService: AuthService) {
      translate.setDefaultLang('en');
      this.authService.currentUser.subscribe((user)=> {
        this.translate.use(user.locale);
      });
    }

    ngOnInit(){
      let selectedLanguage = localStorage.getItem('locale');
      if (selectedLanguage){
        this.translate.use(selectedLanguage);
      }
    }
}
