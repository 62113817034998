import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { CurrentUserService } from "../../core/current-user.service";
import { ProfileService } from "../../services/profile.service";
import { TranslateService } from "@ngx-translate/core";
var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var LoginComponent = /** @class */ (function () {
    function LoginComponent(auth, currentUserService, activatedRoute, router, profileService, translate) {
        this.auth = auth;
        this.currentUserService = currentUserService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.profileService = profileService;
        this.translate = translate;
        this.success = true;
        this.emailFormControl = new FormControl('', [
            Validators.required,
            Validators.pattern(EMAIL_REGEX)
        ]);
    }
    LoginComponent.prototype.ngOnInit = function () {
    };
    LoginComponent.prototype.userLogin = function (email, password) {
        var _this = this;
        this.auth.login({ email: email, password: password }).subscribe(function (result) {
            var returnUrl = _this.activatedRoute.snapshot.queryParams['returnUrl'];
            _this.currentUserService.set(result);
            //this.requestProcessing = false;
            _this.profileService.getUserProfile().subscribe(function (value) {
                _this.translate.use(value.language);
                _this.auth.currentUser.emit({ user: result.user, locale: value.language });
            });
            if (returnUrl) {
                _this.router.navigateByUrl(returnUrl);
            }
            else {
                _this.currentUserService.redirectToDefaultPage();
            }
        }, function (error) {
            var msg = error.status === 401 ? error.error['message'] : "Something wrong.";
            //this.toastr.error(msg);
            //this.requestProcessing = false;
            _this.success = false;
        }, function () {
            _this.success = false; //this.requestProcessing = false;
        });
    };
    return LoginComponent;
}());
export { LoginComponent };
