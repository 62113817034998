import {Injectable, EventEmitter} from "@angular/core";
import {Router, NavigationCancel, NavigationExtras} from "@angular/router";

import {SingleRequestService} from "./single-request.service";
import {StorageService} from "./storage.service";
import {AuthStateService} from "./auth-state.service";

import {AppEnums} from "./app-constants";

@Injectable()
export class CurrentUserService extends SingleRequestService {

  private static user: any;

  public onUserLogout = new EventEmitter<any>();

  constructor(private storageService: StorageService,
              private authStateService: AuthStateService,
              private _router: Router) {
    super();

    CurrentUserService.user = this.storageService.get(AppEnums.storageKeys.User);

    this.authStateService.onTokenClear.subscribe(() => this.clear());

  }

  set(userDetails: any) {
    CurrentUserService.user = userDetails.user;
    this.storageService.set(AppEnums.storageKeys.User, CurrentUserService.user);
    this.authStateService.setToken(userDetails.token);
  }

  get() {
    return CurrentUserService.user;
  }

  getRoleType() {
    return this.isLoggedIn() ? CurrentUserService.user.role : null;
  }


  isLoggedIn(): boolean {
    return !!CurrentUserService.user;
  }

  static getDefaultPageUrl(): string {
    if (!CurrentUserService.user) return '/login';
    return `/batches`;
  }

  redirectToLoginPage(returnUrl:string): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {returnUrl: returnUrl}
    };

    this._router.navigate(['login'], navigationExtras);
  }

  redirectToDefaultPage(): void {
    this._router.navigate(['/batches']);
  }

  redirectToChangePassword(token: string, returnUrl:string): void {
    const navigationExtras: NavigationExtras = {
      queryParams: {'session_id': token, returnUrl: returnUrl}
    };

    this._router.navigate(['account'], navigationExtras);
  }

  clear(): void {

    if (CurrentUserService.user === null) return;

    CurrentUserService.user = null;
    this.storageService.remove(AppEnums.storageKeys.User);
    this.onUserLogout.emit(null);
  }

  logout(): void {
    this.clear();
    this.redirectToLoginPage(null);
  }
}
