/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./input-file.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/form-field";
import * as i3 from "./input-file.component";
import * as i4 from "@angular/forms";
import * as i5 from "@angular/cdk/a11y";
var styles_InputFileComponent = [i0.styles];
var RenderType_InputFileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InputFileComponent, data: {} });
export { RenderType_InputFileComponent as RenderType_InputFileComponent };
export function View_InputFileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, [["input", 1]], null, 0, "input", [["id", "md-input-file"], ["type", "file"]], [[1, "multiple", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "filename"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.multiple ? "" : null); _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.fileNames; _ck(_v, 3, 0, currVal_1); }); }
export function View_InputFileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-input-file", [], [[8, "id", 0], [1, "aria-describedby", 0], [2, "mat-form-field-should-float", null], [2, "file-input-disabled", null]], [[null, "change"], [null, "focusout"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).change($event) !== false);
        ad = (pd_0 && ad);
    } if (("focusout" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).blur() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_InputFileComponent_0, RenderType_InputFileComponent)), i1.ɵprd(6144, null, i2.MatFormFieldControl, null, [i3.InputFileComponent]), i1.ɵdid(2, 245760, null, 0, i3.InputFileComponent, [i4.NgControl, i5.FocusMonitor, i1.ElementRef, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = i1.ɵnov(_v, 2).describedBy; var currVal_2 = i1.ɵnov(_v, 2).shouldPlaceholderFloat; var currVal_3 = i1.ɵnov(_v, 2).isDisabled; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
var InputFileComponentNgFactory = i1.ɵccf("app-input-file", i3.InputFileComponent, View_InputFileComponent_Host_0, { valuePlaceholder: "valuePlaceholder", multiple: "multiple", value: "value", placeholder: "placeholder", required: "required", disabled: "disabled", errorState: "errorState" }, {}, []);
export { InputFileComponentNgFactory as InputFileComponentNgFactory };
