var FileInput = /** @class */ (function () {
    function FileInput(_files, delimiter) {
        if (delimiter === void 0) { delimiter = ', '; }
        this._files = _files;
        this.delimiter = delimiter;
        this._fileNames = this._files.map(function (f) { return f.name; }).join(delimiter);
    }
    Object.defineProperty(FileInput.prototype, "files", {
        get: function () {
            return this._files || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileInput.prototype, "fileNames", {
        get: function () {
            return this._fileNames;
        },
        enumerable: true,
        configurable: true
    });
    return FileInput;
}());
export { FileInput };
