<div class="add-padding flex flex-direction-row align-items-center justify-center container ">
  <mat-card class="organization-profile-container">
    <mat-card-title>{{'organization.title-organization' | translate}}</mat-card-title>
    <mat-card-content>
      <form [formGroup]="orgProfileForm">

        <mat-list class="org-mat-list">
          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.org-name' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" value="{{orgProfile.orgName}}" formControlName="orgName">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.address1' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" value="{{orgProfile.address1}}" formControlName="address1">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.address2' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" value="{{orgProfile.address2}}" formControlName="address2">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.org-type' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" value="{{orgProfile.orgType}}" formControlName="orgType">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item class="text-area">
            <label>{{'organization.description' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <textarea matInput type="text" value="{{orgProfile.description}}"
                          rows="3"
                          formControlName="description"></textarea>
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.contact-mail' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="email" value="{{orgProfile.contactMail}}" formControlName="contactMail">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.contact-phone' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="tel" value="{{orgProfile.contactPhone}}" formControlName="contactPhone">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'organization.contact-person' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" value="{{orgProfile.contactPerson}}" formControlName="contactPerson">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

          <mat-list-item>
            <div class="form-submit">
              <button mat-raised-button type="submit" (click)="saveOrganization()"
                      [disabled]="requestProcessing || isError || !orgProfileForm.valid">
                {{'organization.save' | translate}}
              </button>
              <mat-error *ngIf="isError"> {{errorMsg}}</mat-error>
            </div>
          </mat-list-item>

        </mat-list>
      </form>
    </mat-card-content>
  </mat-card>
</div>
