import { Component, OnInit } from '@angular/core';
import {CurrentUserService} from "../core/current-user.service";
import {AuthService} from "../auth/auth.service";
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {OrganizationService} from "../services/organization.service";
import {OrganizationSmallDto, UserDto} from '../core/app.interfaces';
import {Router} from "@angular/router";
import * as _ from 'lodash';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit{
  selectedLanguage: string = 'en';
  selectedOrganization = null as OrganizationSmallDto;
  orgs = [] as OrganizationSmallDto[];
  langVisible = true;
  currentUser = null as UserDto;

  constructor(private authService: AuthService, private currentUserService: CurrentUserService, private translate: TranslateService,
              private organizationService: OrganizationService, private router: Router
) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
    router.events.subscribe(url => {
      if (url['url']){
        const firstChildren = url['url'].split('?')[0];
        this.langVisible = (firstChildren === '/login' || firstChildren === '/');
      }
    });
    this.authService.currentUser.subscribe((user)=> {
      this.currentUser = user.user;
      localStorage.setItem('locale', user.locale);
      this.loadPars();
    });
  }

  ngOnInit() {
    if(this.currentUserService.get()) {
      this.loadPars();
    }
  }

  loadPars(){
    this.organizationService.getOrganizations().subscribe((value)=> {
      this.orgs = value;
      this.currentUser = this.currentUserService.get();
      this.selectedOrganization = (!!value && value.length>0) ? _.find(this.orgs, {id: this.currentUser.org}) : null;
    }, (error)=> {
      // TODO: handle error
    });
  }

  changeLocale(){
    this.translate.use(this.selectedLanguage);
  }

  changeOrganization($event){
    this.selectedOrganization = $event.value;
    this.organizationService.changeOrg(this.selectedOrganization.id).subscribe((value) =>{
       this.currentUserService.set(value);
       window.location.reload();
    },
      error => {
        console.error(error);
      });

  }

  logout() {
    this.authService.logout().subscribe((result) => {
      this.currentUserService.logout();
    });
  }

}
