<div class="flex flex-direction-column justify-center add-margin container">
  <div id="left">
    <mat-card class="">
      <!-- Header and description <mat-card-title>Batches</mat-card-title> -->
      <mat-card-title>{{ 'batches.title' | translate }}</mat-card-title>
      <!--<mat-card-actions layout="row" layout-align="end center">
        <button mat-button class="batches-action md-primary" routerLink="/batches/start">Start</button >
      </mat-card-actions>-->
      <!-- Table -->
      <mat-card-content>
        <div class="batches-container">
          <div class="batches-loading-shade" *ngIf="isLoadingResults||isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="batches-rate-limit-reached" *ngIf="isRateLimitReached">
              REST API rate limit has been reached. It will be reset in one minute.
            </div>
          </div>

          <mat-table #table [dataSource]="dataSource" class="batches-table right"
                     matSort matSortDisableClear="true" matSortActive="registration" matSortDirection="desc">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

            <!-- Date Column -->
            <ng-container matColumnDef="registration">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.date' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.registration | date:'yyyy-MM-dd HH:mm a'}}</mat-cell>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="eMail">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.email' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.eMail}}</mat-cell>
            </ng-container>

            <!-- Template Column -->
            <ng-container matColumnDef="templateId">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.template' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row"> {{row.templateId === null || row.templateId === 'undefined' ? 'Defined by file' : row.templateId}}</mat-cell>
            </ng-container>

            <!--Input  Column -->
            <ng-container matColumnDef="iCount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.input.title' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-chip-list>
                  <div (click)="downloadFile(row.id,'original')" matTooltip="{{'batches.table.input.tooltip' | translate}}">
                    <mat-icon class="md-icon" matBadgeColor="primary" matBadge="{{row.iCount}}">input</mat-icon>
                    <!--<mat-chip class="chipIcon" color="primary" selected="true">-->
                      <!--{{row.iCount}}-->
                    <!--</mat-chip>-->
                  </div>
                </mat-chip-list>
              </mat-cell>
            </ng-container>

            <!--Output  Column -->
            <ng-container matColumnDef="rCount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.output.title' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-chip-list>
                  <button mat-icon-button matTooltip="Download result file" [disabled]="!(row.state<0||row.state>=100)" color="black" (click)="downloadFile(row.id,'result')">
                    <mat-icon class="chipIcon" matBadgeColor="primary" matBadge="{{row.rCount}}">file_download</mat-icon>
                  </button>
                </mat-chip-list>
              </mat-cell>
            </ng-container>

            <!--Error  Column -->
            <ng-container matColumnDef="eCount">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.errors.title' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-chip-list *ngIf="row.errorText!==''">
                  <button mat-icon-button matTooltip="{{'batches.table.errors.tooltip-download' | translate}}" [disabled]="!(row.state<0||row.state>=100)" color="black" (click)="downloadFile(row.id,'error')">
                    <mat-icon class="md-icon" matBadgeColor="warn" matBadge="{{row.eCount}}">bug_report</mat-icon>
                  </button>
                  <button mat-icon-button matTooltip="{{'batches.table.errors.tooltip-push' | translate}}" [disabled]="!pushVisible(row)" color="black" (click)="pushBatch(row.id)">
                    <mat-icon class="md-icon">repeat</mat-icon>
                  </button>
                </mat-chip-list>
              </mat-cell>
            </ng-container>


            <!--State  Column -->
            <ng-container matColumnDef="state">
              <mat-header-cell *matHeaderCellDef mat-sort-header>{{'batches.table.state' | translate}}</mat-header-cell>
              <mat-cell *matCellDef="let row">
                <mat-progress-spinner *ngIf="(row.state>0&&row.state<100)" class="" [value]="row.state"
                                      [diameter]="32"></mat-progress-spinner>
                <mat-cell *ngIf="(row.state<0||row.state>=100)">{{row.state==100?'Done':row.state}}</mat-cell>
              </mat-cell>
            </ng-container>

            <!--Count  Purchase -->
            <ng-container matColumnDef="pCount">
              <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-flex-width'">{{'batches.table.purchase.title' | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'column-flex-width'">

                <mat-chip-list>
                  <div [routerLink]="['/purchases']" [queryParams]="{batchId: row.id, c_sort: sort.active, c_order: sort.direction, c_limit: paginator.pageSize, c_page: paginator.pageIndex}" >
                    <button mat-icon-button matTooltip="{{'batches.table.purchase.tooltip' | translate}}" [disabled]="!(row.state<0||row.state>=100)" color="black" type="button">
                      <mat-icon class="md-icon" matBadgeColor="primary" matBadge="{{row.pCount}}">monetization_on</mat-icon>
                    </button>

                    <!--<mat-chip class="chipIcon" color="primary" selected="true">-->
                      <!--{{row.pCount}}-->
                    <!--</mat-chip>-->
                  </div>
                </mat-chip-list>

              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="delete">
              <mat-header-cell *matHeaderCellDef mat-sort-header [ngClass]="'column-flex-width'">{{'batches.table.delete.title' | translate}}
              </mat-header-cell>
              <mat-cell *matCellDef="let row" [ngClass]="'column-flex-width'">
                <button mat-icon-button type="button" matTooltip="{{'batches.table.delete.tooltip' | translate}}" (click)="openDeleteDialog(row)" [disabled]="!((row.state<0||row.state>=100) && row.pCount===0)">
                  <mat-icon>delete</mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <!--<mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>-->
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>

          <mat-paginator #paginator
                         [length]="resultsLength"
                         [pageSize]="pageSize"
                         [pageSizeOptions]="[10, 25, 50, 100]">
          </mat-paginator>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
  <div id="right">
    <mat-card class="">
      <!-- Header and description <mat-card-title>Batches</mat-card-title> -->
      <mat-card-title>{{'batches.form.title' | translate}}</mat-card-title>
      <mat-card-content>
        <form [formGroup]="addBatchForm" #form="ngForm" (ngSubmit)="addBatch(addBatchForm.value)" novalidate>

          <mat-form-field class="input-container">
            <input matInput placeholder="{{'batches.form.email.placeholder' | translate}}"  formControlName="eMail">
          </mat-form-field>

          <mat-form-field>
            <mat-icon *ngIf="!disabled" matPrefix class="small">description</mat-icon>
            <input matInput placeholder="{{'batches.form.template.placeholder' | translate}}" #template formControlName="templateId">
            <button matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    [matMenuTriggerFor]="menu"
                    class="small"
                    (click)="$event.stopPropagation()"
                    type="button">
              <mat-icon>view_list</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="onFileDefined()" type="button">
                <span>{{'batches.form.template.file-defined' | translate}}</span>
              </button>
              <button *ngIf="templates.length==0" mat-menu-item (click)="onManual()" type="button">
                <span>{{'batches.form.template.manual' | translate}}</span>
              </button>
              <button mat-menu-item *ngFor="let template of templates" type="button" (click)="onFileDefined(template)">
                <mat-icon>description</mat-icon>
                <span>{{template.name}}</span>
              </button>
            </mat-menu>
          </mat-form-field>

          <mat-form-field class="input-container">
            <app-input-file formControlName="fileInput" placeholder="{{'batches.form.batch-file.placeholder' | translate}}"
                            (change)='openFile($event)'></app-input-file>
            <button matSuffix mat-icon-button class="small" type="button">
              <mat-icon>folder</mat-icon>
            </button>
          </mat-form-field>

          <button mat-raised-button type="submit" [disabled]="!addBatchForm.valid||!isLoaded()">{{'batches.form.submit-button' | translate}}</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
