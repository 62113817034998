<div class="add-padding flex flex-direction-row align-items-center justify-center container">
  <mat-card class="card">
    <mat-card-title style="word-wrap: break-word"><div [innerHTML]="'auth.title' | translate"></div></mat-card-title>
    <!-- <mat-card-subtitle>If you don't have credentials, login as guest</mat-card-subtitle> -->
    <mat-card-content>
      <form class="login-form" (keydown.enter)="userLogin(email.value, password.value)">
        <div fxLayout="row" fxLayoutAlign="center">
          <div fxLayout="column" fxFlex="80">
            <mat-form-field>
              <input matInput #email placeholder="{{'auth.email-placeholder' | translate}}" type="email" [formControl]="emailFormControl">
              <mat-error *ngIf="emailFormControl.hasError('pattern')">
                {{'auth.email-validation-error' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxFlex="80">

            <mat-form-field>
              <input matInput #password placeholder="{{'auth.password-placeholder' | translate}}" type="password">
            </mat-form-field>
          </div>
        </div>
        <mat-error class="error" *ngIf="!success">
          {{'auth.auth-error' | translate}}
        </mat-error>
        <!--  <p routerLink="/resetpassword" class="reset error">Forgot password?</p> -->
      </form>


      <button mat-raised-button (click)="userLogin(email.value, password.value)">{{'auth.submit-button' | translate}}</button>
      <p><a class="label-link" routerLink="/reset-password">{{'auth.reset-link' | translate}}</a></p>
    </mat-card-content>
  </mat-card>
</div>
