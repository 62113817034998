import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfileService } from "./services/profile.service";
import { AuthService } from "./auth/auth.service";
var AppComponent = /** @class */ (function () {
    function AppComponent(translate, profileService, authService) {
        var _this = this;
        this.translate = translate;
        this.profileService = profileService;
        this.authService = authService;
        translate.setDefaultLang('en');
        this.authService.currentUser.subscribe(function (user) {
            _this.translate.use(user.locale);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var selectedLanguage = localStorage.getItem('locale');
        if (selectedLanguage) {
            this.translate.use(selectedLanguage);
        }
    };
    return AppComponent;
}());
export { AppComponent };
