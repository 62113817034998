import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {IFilterFields, IPurchaseTable} from '../purchase/purchase';
import {environment} from '../../environments/environment';
import {throwError} from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class PurchaseService {
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
  }

  getPurchase(sort: string, order: string, limit: number, page: number, form: IFilterFields): Observable<IPurchaseTable> {
    form.name = form.name ? form.name : '';
    form.email = form.email ? form.email : '';
    form.dateStart = form.dateStart ? form.dateStart : '';
    form.dateEnd = form.dateEnd ? form.dateEnd : '';
    form.batchId = form.batchId ? form.batchId : '';
    return this.http.get<IPurchaseTable>(`${environment.apiUrl}/purchasesjoin/filter/?sort=${sort}&order=${order}&skip=${limit * page}&take=${limit}&name=${form.name}&email=${form.email}&dateStart=${form.dateStart}&dateEnd=${form.dateEnd}&batchID=${form.batchId}`, httpOptions)
      .catch(this.handleError);
  }

  private handleError(error: any) {
    // In a real world app, we might send the error to remote logging infrastructure before returning/sending the error
    let errMsg = error.message || 'Server error'; // transform the error into a user-friendly message
    console.log(errMsg);
    return throwError(errMsg); // returns the message in a new, failed observable
  }
}
