var FormErrorStateMatcher = /** @class */ (function () {
    function FormErrorStateMatcher() {
    }
    FormErrorStateMatcher.prototype.isErrorState = function (control, form) {
        var invalidCtrl = (control && control.invalid && control.parent.dirty);
        var invalidParent = (control && control.parent && control.parent.invalid && control.parent.dirty);
        return (invalidCtrl || invalidParent);
    };
    return FormErrorStateMatcher;
}());
export { FormErrorStateMatcher };
