import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var ProfileService = /** @class */ (function () {
    function ProfileService(http) {
        this.http = http;
    }
    ProfileService.prototype.getUserProfile = function () {
        return this.http.get(environment.apiUrl + "/profile/");
    };
    ProfileService.prototype.updateUserProfile = function (userProfile) {
        return this.http.put(environment.apiUrl + "/profile/", userProfile, httpOptions);
    };
    ProfileService.prototype.changePassword = function (body) {
        return this.http.post(environment.apiUrl + "/auth/password", body);
    };
    return ProfileService;
}());
export { ProfileService };
