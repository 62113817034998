import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ChangePasswordDto, OrganizationSmallDto, UserProfileDto} from '../../core/app.interfaces';
import {CurrentUserService} from '../../core/current-user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {FormErrorStateMatcher} from '../../core/form-error-state-matcher';
import {MatSnackBar} from '@angular/material';
import {ProfileService} from '../../services/profile.service';
import {OrganizationService} from '../../services/organization.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public currentUser: UserProfileDto = {
    name: '',
    email: '',
    language: this.translate.currentLang,
    organization: ''
  };
  public orgs = [];

  public selectedOrganization: string;
  public selectedLanguage: string;

  public changePassForm: FormGroup;
  public userProfileForm: FormGroup;

  public requestProcessing: boolean;
  public requestSaveUserProfile: boolean;

  public errorMsg: string;
  public errorMsgProfile: string;

  public isError: boolean;
  public isErrorProfile: boolean;

  matcher = new FormErrorStateMatcher();

  constructor(private formBuilder: FormBuilder,
              private userProfile: ProfileService,
              private currentUserService: CurrentUserService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private translate: TranslateService,
              private snackBar: MatSnackBar,
              private organizationService: OrganizationService) {
    this.changePassForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(4)]],
      confirmPassword: ['']
    }, {validator: this.checkPasswords});
    this.userProfileForm = this.formBuilder.group({
      name: [''],
      email: [''],
      language: [''],
      organization: ['']
    });
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.selectedLanguage = event.lang;
    });
  }

  ngOnInit() {
    this.readProfile();
    this.organizationService.getOrganizations().subscribe((organizations) => {
      this.orgs = organizations;
    });
    this.isError = false;
    this.isErrorProfile = false;
  }

  readProfile() {
    this.userProfile.getUserProfile().subscribe((user) => {
      this.currentUser = user;
      this.selectedLanguage = user.language;
      this.selectedOrganization = user.organization;
      }, (error: HttpErrorResponse) => {
      const errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      this.snackBar.open('User profile read error. ' + errorMsg, 'OK', {duration: 3000});
      this.isError = true;
      this.isErrorProfile = true;
    });
  }

  changeOrganization($event) {
    this.selectedOrganization = $event.value;
  }

  changeLenguage($event) {
    this.selectedLanguage = $event.value;
  }

  saveProfile() {
    if (!this.userProfileForm.valid) {
      return;
    }
    this.requestSaveUserProfile = true;
    this.userProfile.updateUserProfile({
      name: this.userProfileForm.controls.name.value || this.currentUser.name,
      email: this.userProfileForm.controls.email.value || this.currentUser.email,
      language: this.selectedLanguage,
      organization: this.selectedOrganization
    } as UserProfileDto).subscribe((result) => {
      this.translate.use(result.language);
      localStorage.setItem('locale', result.language);
      this.requestSaveUserProfile = false;
      this.snackBar.open('Profile save successful.', 'OK', {duration: 3000});
    }, (error: HttpErrorResponse) => {
      this.errorMsgProfile = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      this.isErrorProfile = true;
      this.requestSaveUserProfile = false;
    });
  }

  checkPasswords(group: FormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    return pass === confirmPass ? null : {notSame: true};
  }

  savePassword() {
    if (!this.changePassForm.valid) {
      return;
    }
    this.requestProcessing = true;

    this.userProfile.changePassword({
      password: this.changePassForm.controls.password.value,
      confirmPassword: this.changePassForm.controls.confirmPassword.value
    } as ChangePasswordDto).subscribe((result) => {
      this.currentUserService.set(result);
      this.requestProcessing = false;
      this.isError = false;
      this.snackBar.open('Password save successful.', 'OK', {duration: 3000});

      this.changePassForm.reset();
    }, (error: HttpErrorResponse) => {
      this.errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
      this.isError = true;
      this.requestProcessing = false;
    });
  }
}
