import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var OrganizationService = /** @class */ (function () {
    function OrganizationService(http) {
        this.http = http;
    }
    OrganizationService.prototype.getOrganization = function () {
        return this.http.get(environment.apiUrl + "/organizations/current/");
    };
    OrganizationService.prototype.updateOrganizationProfile = function (orgProfile) {
        return this.http.put(environment.apiUrl + "/organizations/update", orgProfile, httpOptions);
    };
    OrganizationService.prototype.getOrganizations = function () {
        return this.http.get(environment.apiUrl + "/organizations/all");
    };
    OrganizationService.prototype.changeOrg = function (org_id) {
        return this.http.post(environment.apiUrl + "/auth/changeorg", { org: org_id });
    };
    OrganizationService.prototype.createUser = function (org_id) {
        return this.http.put(environment.apiUrl + "/organizations/user/create", { id: org_id });
    };
    return OrganizationService;
}());
export { OrganizationService };
