import { OnInit } from '@angular/core';
import { BatchesService } from "../services/batches.service";
import { CurrentUserService } from "../core/current-user.service";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import * as _ from 'lodash';
import { MatDialog } from "@angular/material";
import { APP_DATE_FORMATS } from "./date-adapter";
import { SuccessDialogComponent } from "./success-dialog/success-dialog.component";
import { NewCertificateService } from "../services/new-certificate.service";
var ɵ0 = APP_DATE_FORMATS;
var NewCertificateComponent = /** @class */ (function () {
    function NewCertificateComponent(currentUserService, batchesService, newCertService, formBuilder, dialog) {
        this.currentUserService = currentUserService;
        this.batchesService = batchesService;
        this.newCertService = newCertService;
        this.formBuilder = formBuilder;
        this.dialog = dialog;
        this.templates = [];
        this.selectedTemplate = {};
        this.defaultLanguage = 'en';
        this.defaultCurrency = 'usd';
        this.state = false;
        this.fields = [];
        this.show = false;
        this.isLoadingResults = true;
        this.isLoadingFields = false;
        this.isLoadingResponse = false;
        this.sLockMail = true;
        this.addPurchaseForm = this.formBuilder.group({
            'sName': ['', [Validators.required]],
            'eMail': ['', [Validators.required, Validators.email]],
            'price': [''],
            'sLockMail': [this.sLockMail],
            'sLang': [this.defaultLanguage],
            'currency': [this.defaultCurrency],
            'templateId': [''],
            'issuedAt': [''],
            'name': [''],
            'criteria': [''],
            'expiresAt': ['']
        });
    }
    NewCertificateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.animateToTop();
        this.batchesService.getTemplateList().subscribe(function (list) {
            _this.templates = list.templates;
            _this.isLoadingResults = false;
            _this.currentUser = _this.currentUserService.get();
            _this.addPurchaseForm.patchValue({
                eMail: _this.currentUser.email,
                sName: _this.currentUser.name
            });
        }, function (error) {
            _this.isLoadingResults = false;
            _this.batchesService.showSnackBar('Error loading templates!');
        });
    };
    NewCertificateComponent.prototype.onChoseTemplate = function (template) {
        var _this = this;
        this.isLoadingFields = true;
        if (template._id !== this.selectedTemplate._id) {
            this.selectedTemplate = template;
            this.newCertService.getTemplate(template._id).subscribe(function (result) {
                _this.fields = _.unionBy(result.templates.fields, 'name');
                _this.fields.forEach(function (field) {
                    _this.addPurchaseForm.addControl(field.name, new FormControl(''));
                });
                _this.addPurchaseForm.patchValue({ templateId: template._id });
                _this.isLoadingFields = false;
                _this.show = true;
            }, function (error) {
                _this.isLoadingFields = false;
                _this.batchesService.showSnackBar('Error loading template!');
            });
        }
        else {
            this.isLoadingFields = false;
        }
    };
    NewCertificateComponent.prototype.newBatch = function (value) {
        var _this = this;
        if (this.addPurchaseForm.valid) {
            value['sLockMail'] = !this.sLockMail;
            value['price'] = value['price'].toString();
            this.isLoadingResponse = true;
            this.newCertService.newPurchase(value).subscribe(function (result) {
                _this.isLoadingResponse = false;
                if (result.certificate) {
                    _this.openDialog(result);
                }
                else {
                    _this.batchesService.showSnackBar('Connection failed');
                }
                // this.addPurchaseForm.reset();
            }, function (error) {
                _this.batchesService.showSnackBar('Error creating purchase');
            });
            this.animateToTop();
        }
        else {
            this.isLoadingResponse = false;
            this.batchesService.showSnackBar('Please fill out all required fields!');
        }
    };
    NewCertificateComponent.prototype.openDialog = function (response) {
        var dialogRef = this.dialog.open(SuccessDialogComponent, {
            width: '500px',
            autoFocus: false,
            data: response
        });
    };
    NewCertificateComponent.prototype.animateToTop = function () {
        var scrollToTop = window.setInterval(function () {
            var pos = window.pageYOffset;
            if (pos > 0) {
                window.scrollTo(0, pos - 20);
            }
            else {
                window.clearInterval(scrollToTop);
            }
        }, 16);
    };
    return NewCertificateComponent;
}());
export { NewCertificateComponent };
export { ɵ0 };
