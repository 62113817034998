import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var DeleteDialogComponent = /** @class */ (function () {
    function DeleteDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    DeleteDialogComponent.prototype.ngOnInit = function () {
    };
    DeleteDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close(false);
    };
    return DeleteDialogComponent;
}());
export { DeleteDialogComponent };
