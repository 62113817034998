import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from "@angular/router";

import {Observable} from "rxjs/Observable";
import {CurrentUserService} from "./core/current-user.service";
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Injectable()
export class CanActivateApp implements CanActivate {

  //constructor(private currentUserService: CurrentUserService, private spinnerService: Ng4LoadingSpinnerService) {
  constructor(private currentUserService: CurrentUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>
    | Promise<boolean>
    | boolean {

    if (this.currentUserService.isLoggedIn()) {
      return true;
    } else {
      this.currentUserService.redirectToLoginPage(state.url);
      return false;
    }

  }

}
