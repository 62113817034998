import { Validators, FormBuilder } from '@angular/forms';
import { CurrentUserService } from '../../core/current-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormErrorStateMatcher } from '../../core/form-error-state-matcher';
var BatchPasswordComponent = /** @class */ (function () {
    function BatchPasswordComponent(formBuilder, authService, currentUserService, activatedRoute, router) {
        this.formBuilder = formBuilder;
        this.authService = authService;
        this.currentUserService = currentUserService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.$isError = false;
        this.matcher = new FormErrorStateMatcher();
        this.changePassForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(4)]],
            confirmPassword: ['']
        }, { validator: this.checkPasswords });
    }
    BatchPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.token = this.activatedRoute.snapshot.params['token'];
        if (!this.token || this.token === '') {
            this.router.navigate(['login']);
        }
        this.authService.getResetPassword({ token: this.token }).subscribe(function (entity) {
        }, function (error) {
            _this.errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.$isError = true;
            _this.router.navigate(['login']);
        });
    };
    BatchPasswordComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.password.value;
        var confirmPass = group.controls.confirmPassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    BatchPasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        if (!this.changePassForm.valid) {
            return;
        }
        this.requestProcessing = true;
        this.authService.postResetPassword({ token: this.token }, {
            password: this.changePassForm.controls.password.value,
            confirmPassword: this.changePassForm.controls.confirmPassword.value
        }).subscribe(function (result) {
            _this.currentUserService.set(result);
            var returnUrl = _this.activatedRoute.snapshot.queryParams['returnUrl'];
            if (returnUrl) {
                _this.router.navigateByUrl(returnUrl);
            }
            else {
                _this.currentUserService.redirectToDefaultPage();
            }
            _this.requestProcessing = false;
        }, function (error) {
            _this.errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.$isError = true;
            _this.requestProcessing = false;
        });
    };
    return BatchPasswordComponent;
}());
export { BatchPasswordComponent };
