<div class="add-padding flex flex-direction-row align-items-center justify-center container">
  <mat-card>
    <mat-card-title>{{'new-password.title' | translate}}</mat-card-title>
    <mat-card-content class="batch-password-container">
      <form [formGroup]="changePassForm" (keydown.enter)="onSubmit()">
        <div fxLayout="row" fxLayoutAlign="center">
          <div fxLayout="column" fxFlex="100">
            <mat-form-field>
              <input matInput type="password" placeholder="{{'new-password.password-placeholder' | translate}}" formControlName="password"
                     maxlength="32" autocomplete="off" required>
              <mat-error *ngIf="changePassForm.hasError('required', ['password'])">
                {{'new-password.password-required-error' | translate}}
              </mat-error>
              <mat-error *ngIf="changePassForm.hasError('minlength', ['password'])">
                {{'new-password.password-length-error' | translate}}
              </mat-error>
            </mat-form-field>
          </div>
            <div fxLayout="column" fxFlex="100">
              <mat-form-field>
                <input matInput placeholder="{{'new-password.confirm-password-placeholder' | translate}}" type="password" formControlName="confirmPassword"
                       maxlength="32" autocomplete="off" [errorStateMatcher]="matcher">
                <mat-error *ngIf="changePassForm.hasError('notSame')">
                  {{'new-password.password-match-error' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
        </div>
        <div [hidden]="!$isError">
          <mat-error class="error">
            {{errorMsg}}
          </mat-error>
        </div>
      </form>
      <button class="btn-submit" mat-raised-button type="submit" (click)="onSubmit()" [disabled]="!changePassForm.valid">
        {{'new-password.submit-button' | translate}}
      </button>
    </mat-card-content>
  </mat-card>
</div>
