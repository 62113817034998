import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { environment } from '../../environments/environment';
import { throwError } from 'rxjs';
var httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
var PurchaseService = /** @class */ (function () {
    function PurchaseService(http) {
        this.http = http;
        this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' });
    }
    PurchaseService.prototype.getPurchase = function (sort, order, limit, page, form) {
        form.name = form.name ? form.name : '';
        form.email = form.email ? form.email : '';
        form.dateStart = form.dateStart ? form.dateStart : '';
        form.dateEnd = form.dateEnd ? form.dateEnd : '';
        form.batchId = form.batchId ? form.batchId : '';
        return this.http.get(environment.apiUrl + "/purchasesjoin/filter/?sort=" + sort + "&order=" + order + "&skip=" + limit * page + "&take=" + limit + "&name=" + form.name + "&email=" + form.email + "&dateStart=" + form.dateStart + "&dateEnd=" + form.dateEnd + "&batchID=" + form.batchId, httpOptions)
            .catch(this.handleError);
    };
    PurchaseService.prototype.handleError = function (error) {
        // In a real world app, we might send the error to remote logging infrastructure before returning/sending the error
        var errMsg = error.message || 'Server error'; // transform the error into a user-friendly message
        console.log(errMsg);
        return throwError(errMsg); // returns the message in a new, failed observable
    };
    return PurchaseService;
}());
export { PurchaseService };
