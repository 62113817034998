import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Injectable} from '@angular/core';
import {LoginResultDto, OrganizationProfileDto, OrganizationSmallDto, UserDto} from '../core/app.interfaces';
import {environment} from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class OrganizationService {

  constructor(private http: HttpClient) {
  }

  getOrganization(): Observable<OrganizationProfileDto> {
    return this.http.get(`${environment.apiUrl}/organizations/current/`) as Observable<OrganizationProfileDto>;
  }

  updateOrganizationProfile(orgProfile: OrganizationProfileDto): Observable<OrganizationProfileDto> {
    return this.http.put(`${environment.apiUrl}/organizations/update`, orgProfile, httpOptions) as Observable<OrganizationProfileDto>;
  }

  getOrganizations(): Observable<OrganizationSmallDto[]> {
    return this.http.get(`${environment.apiUrl}/organizations/all`) as Observable<OrganizationSmallDto[]>;
  }

  changeOrg(org_id: string): Observable<LoginResultDto> {
    return this.http.post<LoginResultDto>(`${environment.apiUrl}/auth/changeorg`, {org: org_id});
  }

  createUser(org_id: string): Observable<UserDto> {
    return this.http.put<UserDto>(`${environment.apiUrl}/organizations/user/create`, {id: org_id});
  }
}
