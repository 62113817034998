import { AfterViewInit, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { of as observableOf } from 'rxjs/observable/of';
import { merge } from 'rxjs/observable/merge';
import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import { switchMap } from 'rxjs/operators/switchMap';
import { environment } from '../../environments/environment';
import { PurchaseService } from '../services/purchase.service';
var PurchaseComponent = /** @class */ (function () {
    function PurchaseComponent(purchaseService, formBuilder, route, location) {
        this.purchaseService = purchaseService;
        this.formBuilder = formBuilder;
        this.route = route;
        this.location = location;
        this.displayedColumns = ['date', 'name', 'email', 'document', 'price', 'paid'];
        this.resultsLength = 0;
        this.isLoadingResults = true;
        this.isRateLimitReached = false;
        this.dataSource = new MatTableDataSource();
        this.annUrl = environment.annUrl;
        this.formFields = { batchId: '', dateEnd: '', dateStart: '', email: '', name: '' };
        this.showBackButton = false;
        this.callbackToBatches = {};
    }
    PurchaseComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            _this.formFields.batchId = params.batchId || '';
            _this.formFilter.value.batchId = params.batchId || '';
            if (params.c_page) {
                _this.showBackButton = true;
                _this.callbackToBatches.sort = params.c_sort;
                _this.callbackToBatches.limit = params.c_limit;
                _this.callbackToBatches.order = params.c_order;
                _this.callbackToBatches.page = params.c_page;
            }
            _this.location.replaceState('/purchases');
        });
    };
    PurchaseComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.formFields = this.formFilter.value;
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(function () { return _this.paginator.pageIndex = 0; });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(startWith({}), switchMap(function () {
            _this.isLoadingResults = true;
            return _this.purchaseService.getPurchase(_this.sort.active, _this.sort.direction, _this.paginator.pageSize, _this.paginator.pageIndex, _this.formFields);
        }), map(function (data) {
            // Flip flag to show that loading has finished.
            _this.isLoadingResults = false;
            _this.isRateLimitReached = false;
            _this.resultsLength = data.total_count;
            return data.items;
        }), catchError(function () {
            _this.isLoadingResults = false;
            // Catch if the GitHub API has reached its rate limit. Return empty data.
            _this.isRateLimitReached = true;
            return observableOf([]);
        })).subscribe(function (data) {
            _this.dataSource.data = data;
        });
    };
    PurchaseComponent.prototype.applyFilter = function (form) {
        var _this = this;
        this.formFields = this.formFilter.value;
        merge(this.paginator.page)
            .pipe(startWith({}), switchMap(function () {
            _this.isLoadingResults = true;
            return _this.purchaseService.getPurchase(_this.sort.active, _this.sort.direction, _this.paginator.pageSize, _this.paginator.pageIndex, form);
        }), map(function (data) {
            _this.isLoadingResults = false;
            _this.isRateLimitReached = false;
            _this.resultsLength = data.total_count;
            return data.items;
        }), catchError(function () {
            _this.isLoadingResults = false;
            _this.isRateLimitReached = true;
            return observableOf([]);
        })).subscribe(function (data) {
            _this.dataSource.data = data;
        });
    };
    PurchaseComponent.prototype.resetFilter = function () {
        this.formFilter.resetForm();
        this.formFields = this.formFilter.value;
    };
    return PurchaseComponent;
}());
export { PurchaseComponent };
