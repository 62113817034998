import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {IBatch, IBatchesState, IBatchesTable} from "../batches/batches";
import {saveAs} from 'file-saver';
import {MatSnackBar} from '@angular/material';
import {environment} from "../../environments/environment";
import {throwError} from 'rxjs';


@Injectable()
export class BatchesService {
  private headers: HttpHeaders;

  private handleError(error: any, display:boolean = false) {
    console.log(error);
    if(display)
      this.showSnackBar('Error');
    if (error instanceof Response) {
      return throwError(error.json()['error'] || 'backend server error');
    }
    return throwError(error || 'backend server error');
  }

  constructor(private http: HttpClient, private snackBar: MatSnackBar) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json','Accept': 'application/json'});
  }

  ngOnInit() {

  }

  getTable(table: string, sort: string, order: string, limit: number, page: number): Observable<IBatchesTable> {
    return this.http.get<IBatchesTable>(`${environment.apiUrl}/table/${table}/?sort=${sort}&order=${order}&skip=${page + 1}&limit=${limit}`);
  }

  getBatches(sort: string, order: string, limit: number, page: number): Observable<IBatchesTable> {
    return this.http.get<IBatchesTable>(`${environment.apiUrl}/batches/all/?sort=${sort}&order=${order}&skip=${limit*page}&take=${limit}`);
  }


  addBatch(batch: IBatch): Observable<IBatch> { //Deprecated
      return this.http
        .post<IBatch>(`${environment.apiUrl}/batches/add`,
          JSON.stringify(batch),
          {headers: this.headers,reportProgress:true,withCredentials:false})
        .map(response => {
          this.showSnackBar('Batch processing started');
          return response;
        })
        .catch(error => this.handleError(error,true));
  }

  addBatchMultipart(batch: IBatch,file: File): Observable<IBatch> {
    let headers = new HttpHeaders({'Content-Type': 'undefined'});
    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };

    const route=file.name.toLowerCase().endsWith(".zip")?'addmedia':'addbatch';//Different route for *.zip (batch.json+attachments) and  (*.json or *.* as *.csv)

    let fd = new FormData();
    fd.append('eMail',batch.eMail);
    fd.append('templateId',batch.templateId);
    fd.append('file', file, file.name);

    return this.http
      .post<IBatch>(`${environment.apiUrl}/batches/${route}`,
        fd,options)
        //{headers: headers,reportProgress:true,withCredentials:false})
      .map(response => {
        this.showSnackBar('Batch processing started');
        return response;
      })
      .catch(error => this.handleError(error,true));
  }

  pushBatch(id: string) {
    return this.http
      .post(`${environment.apiUrl}/batches/push`,
        JSON.stringify({id:id}),
        {headers: this.headers,reportProgress:true,withCredentials:false})
      .toPromise()
      .then(response => {
        this.showSnackBar('Batch processing started');
        return response;
      })
      .catch(error => this.handleError(error,true));
  }

  getObserved(batches: string[]): Observable<IBatchesState[]> {
    return this.http
      .post<IBatchesState[]>(`${environment.apiUrl}/batches/observed`,
        JSON.stringify({id:batches}),
        {headers: this.headers,reportProgress:true,withCredentials:false})
      .map(response => {
        return response;
      })
      .catch(error => this.handleError(error,true));
  }

  downloadFile(id:string,type:string) {
    const headers = new HttpHeaders({'Accept':'application/json'});
    return this.http.get(`${environment.apiUrl}/batches/result/`+id+'?type='+(type||''), { headers: headers })
      .toPromise()
      .then(response => {
        const blob = new Blob([response['data']], { type: 'text/plain' });
        saveAs(blob, response['name']);
      })
      .catch(error => this.handleError(error,true));
  }

  showSnackBar(name): void {
    this.snackBar.open(name, 'OK', {duration:3000});
  }

  getTemplateList() {
    return this.http.get(`${environment.apiUrl}/templates/all`);
  }

  deleteBatch(batchId: string) {
    return this.http.delete(`${environment.apiUrl}/batches/delete/${batchId}`);
  }
}
