<mat-dialog-content class="title-box">
  <h1 style="margin: 5px;">{{'manual.success-dialog.message' | translate}}</h1>
  <div (click)="closeDialog()" class="link-margin">
    <a href="{{data.certificate}}" target="_blank">{{'manual.success-dialog.view-link' | translate}}</a>
  </div>
  <div (click)="closeDialog()" class="link-margin">
    <a [routerLink]="['/purchases']">{{'manual.success-dialog.purchase-link' | translate}}</a>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" mat-dialog-close>OK</button>
</mat-dialog-actions>

