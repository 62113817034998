import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ChangePasswordDto, LoginResultDto, UserProfileDto} from '../core/app.interfaces';
import {Observable} from 'rxjs/Observable';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class ProfileService {

  constructor(private http: HttpClient) {
  }

  getUserProfile(): Observable<UserProfileDto> {
    return this.http.get(`${environment.apiUrl}/profile/`) as Observable<UserProfileDto>;
  }

  updateUserProfile(userProfile: UserProfileDto): Observable<UserProfileDto> {
    return this.http.put(`${environment.apiUrl}/profile/`, userProfile, httpOptions) as Observable<UserProfileDto>;
  }

  changePassword(body: ChangePasswordDto): Observable<LoginResultDto> {
    return this.http.post(`${environment.apiUrl}/auth/password`, body) as Observable<LoginResultDto>;
  }

}
