<div class="add-padding flex flex-direction-row align-items-center justify-center container ">
  <mat-card class="user-profile-container">
    <mat-card-title>{{'user-profile.title-profile' | translate}}</mat-card-title>
    <mat-card-content>
      <form [formGroup]="userProfileForm">

        <mat-list>
          <mat-divider></mat-divider>
          <mat-list-item>
            <label>{{'user-profile.name-label' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <input matInput type="text" value="{{currentUser.name}}" formControlName="name">
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

          <mat-list-item>
            <label>{{'user-profile.email-label' | translate}}</label>
            <div class="form-group">
              <!--<mat-form-field>-->
              <input matInput type="email" value="{{currentUser.email}}" formControlName="email" readonly>
              <input matInput type="text"/>
              <!--</mat-form-field>-->
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

          <mat-list-item>
            <label>{{'user-profile.language-label' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <mat-select (selectionChange)="changeLenguage($event)" [(ngModel)]="selectedLanguage" formControlName="language">
                  <mat-option value="en">English</mat-option>
                  <mat-option value="uk">Українська</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

          <mat-list-item>
            <label>{{'user-profile.organization-label' | translate}}</label>
            <div class="form-group">
              <mat-form-field>
                <mat-select (selectionChange)="changeOrganization($event)"
                            [(ngModel)]="selectedOrganization" formControlName="organization" >
                  <mat-option *ngFor="let org of orgs" [value]="org.id">{{org.orgName}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-divider></mat-divider>

          <mat-list-item>
            <div class="form-submit">
              <button mat-raised-button type="submit" (click)="saveProfile()"
                      [disabled]="requestSaveUserProfile ||isError">
                {{'user-profile.save-profile' | translate}}
              </button>
              <mat-error *ngIf="isErrorProfile"> {{errorMsgProfile}}</mat-error>
            </div>
          </mat-list-item>

        </mat-list>
      </form>
    </mat-card-content>
  </mat-card>
</div>

<div class="add-padding flex flex-direction-row align-items-center justify-center container">
  <mat-card class="user-pasword-container">
    <mat-card-title>{{'user-profile.title-password' | translate}}</mat-card-title>
    <mat-card-content>
      <form [formGroup]="changePassForm">
        <span>{{'user-profile.sub-title-password' | translate}}</span>
        <mat-list>
          <!--<mat-list-item>-->
          <!--<div class="form-pass">-->
          <!--<mat-form-field>-->
          <!--<input matInput type="password" placeholder="Current password"-->
          <!--maxlength="32" autocomplete="off" required>-->
          <!--</mat-form-field>-->
          <!--</div>-->
          <!--<span class="text-light">You must provide your current password in order to change it.</span>-->
          <!--</mat-list-item>-->

          <mat-list-item>
            <div class="form-pass">
              <mat-form-field>
                <input matInput type="password" placeholder="{{'user-profile.password-placeholder' | translate}}"
                       formControlName="password" maxlength="32" autocomplete="off" required>
                <mat-error *ngIf="changePassForm.hasError('required', ['password'])">
                  {{'user-profile.password-required-error' | translate}}
                </mat-error>
                <mat-error *ngIf="changePassForm.hasError('minlength', ['password'])">
                  {{'user-profile.password-length-error' | translate}}
                </mat-error>
                <mat-error *ngIf="isError"> {{errorMsg}}</mat-error>
              </mat-form-field>
            </div>
          </mat-list-item>

          <mat-list-item>
            <div class="form-pass">
              <mat-form-field>
                <input matInput type="password"
                       placeholder="{{'user-profile.confirm-password-placeholder' | translate}}"
                       formControlName="confirmPassword" maxlength="32" autocomplete="off"
                       [errorStateMatcher]="matcher">
                <mat-error *ngIf="changePassForm.hasError('notSame')">
                  {{'user-profile.password-match-error' | translate}}
                </mat-error>
              </mat-form-field>
            </div>
          </mat-list-item>


          <mat-list-item>
            <div class="form-submit">
              <button class="btn-submit" mat-raised-button type="submit" (click)="savePassword()"
                      [disabled]="requestProcessing || isError || !changePassForm.valid">
                {{'user-profile.save-password' | translate}}
              </button>
              <!--<a class="label-link" routerLink="/reset-password"> Forgot your password?</a>-->
            </div>
          </mat-list-item>

        </mat-list>
      </form>
    </mat-card-content>
  </mat-card>
</div>
