import { OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { OrganizationService } from '../../services/organization.service';
var OrganizationProfileComponent = /** @class */ (function () {
    function OrganizationProfileComponent(formBuilder, snackBar, organization) {
        this.formBuilder = formBuilder;
        this.snackBar = snackBar;
        this.organization = organization;
        this.orgProfile = {
            orgName: '',
            address1: '',
            address2: '',
            orgType: '',
            description: '',
            contactMail: '',
            contactPhone: '',
            contactPerson: '',
        };
        this.orgProfileForm = this.formBuilder.group({
            orgName: [''],
            address1: [''],
            address2: [''],
            orgType: [''],
            description: [''],
            contactMail: [''],
            contactPhone: [''],
            contactPerson: [''],
        });
    }
    OrganizationProfileComponent.prototype.ngOnInit = function () {
        this.readOrgProfile();
        this.isError = false;
    };
    OrganizationProfileComponent.prototype.readOrgProfile = function () {
        var _this = this;
        this.organization.getOrganization().subscribe(function (org) {
            _this.orgProfile = org;
        }, function (error) {
            var errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.snackBar.open('Organization profile read error. ' + errorMsg, 'OK', { duration: 3000 });
            _this.isError = true;
        });
    };
    OrganizationProfileComponent.prototype.saveOrganization = function () {
        var _this = this;
        if (!this.orgProfileForm.valid) {
            return;
        }
        this.requestProcessing = true;
        this.organization.updateOrganizationProfile({
            orgName: this.orgProfileForm.controls.orgName.value || this.orgProfile.orgName,
            address1: this.orgProfileForm.controls.address1.value || this.orgProfile.address1,
            address2: this.orgProfileForm.controls.address2.value || this.orgProfile.address2,
            orgType: this.orgProfileForm.controls.orgType.value || this.orgProfile.orgType,
            description: this.orgProfileForm.controls.description.value || this.orgProfile.description,
            contactMail: this.orgProfileForm.controls.contactMail.value || this.orgProfile.contactMail,
            contactPhone: this.orgProfileForm.controls.contactPhone.value || this.orgProfile.contactPhone,
            contactPerson: this.orgProfileForm.controls.contactPerson.value || this.orgProfile.contactPerson,
        }).subscribe(function (org) {
            _this.requestProcessing = false;
            _this.isError = false;
            _this.snackBar.open('Organization information update successful.', 'OK', { duration: 3000 });
        }, function (error) {
            _this.extractErrorMessage(error);
            _this.isError = true;
            _this.requestProcessing = false;
        });
    };
    OrganizationProfileComponent.prototype.extractErrorMessage = function (error) {
        var msg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
        this.errorMsg = msg['message'] ? msg['message'] : msg;
    };
    return OrganizationProfileComponent;
}());
export { OrganizationProfileComponent };
