import {Component, OnInit} from '@angular/core';
import { AuthService } from '../auth.service';
import {ActivatedRoute, Router} from '@angular/router';

import { FormControl, Validators } from '@angular/forms';
import {CurrentUserService} from "../../core/current-user.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ProfileService} from "../../services/profile.service";
import {TranslateService} from "@ngx-translate/core";


const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;

  success = true;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(EMAIL_REGEX)]);

  constructor(private auth: AuthService, private currentUserService: CurrentUserService, private activatedRoute: ActivatedRoute,
              private router: Router, private profileService: ProfileService, private translate: TranslateService) { }

  ngOnInit() {
  }

  userLogin(email, password) {
    this.auth.login({email: email,password: password}).subscribe((result) => {

      const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];

      this.currentUserService.set(result);
      //this.requestProcessing = false;
      this.profileService.getUserProfile().subscribe((value) => {
        this.translate.use(value.language);
        this.auth.currentUser.emit({user: result.user, locale: value.language})
      });

      if(returnUrl){
        this.router.navigateByUrl(returnUrl);
      }else{
        this.currentUserService.redirectToDefaultPage();
      }

    }, (error: HttpErrorResponse) => {
      let msg = error.status === 401 ? error.error['message'] : "Something wrong.";
      //this.toastr.error(msg);
      //this.requestProcessing = false;
      this.success = false;
    }, () => {
      this.success = false;//this.requestProcessing = false;
    });
  }

}
