import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {BatchesComponent} from './batches/batches.component';
import {PurchaseComponent} from './purchase/purchase.component';
import {CanActivateApp} from './app-activate';
import {LoginComponent} from './auth/login/login.component';
import {NewCertificateComponent} from './new-certificate/new-certificate.component';
import {BatchResetPasswordComponent} from './auth/reset-password/batch-reset-password.component';
import {BatchPasswordComponent} from './auth/password/batch-password.component';
import {UserProfileComponent} from './profiles/user-profile/user-profile.component';
import {OrganizationProfileComponent} from './profiles/organization-profile/organization-profile.component';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'batches', component: BatchesComponent, canActivate: [CanActivateApp]},
  {path: 'purchases', component: PurchaseComponent, canActivate: [CanActivateApp]},
  {path: 'purchases:batchId', component: PurchaseComponent, canActivate: [CanActivateApp]},
  {path: 'new', component: NewCertificateComponent, canActivate: [CanActivateApp]},
  {path: 'reset-password', component: BatchResetPasswordComponent},
  {path: 'password/:token' ,  component: BatchPasswordComponent},
  {path: 'profile' ,  component: UserProfileComponent, canActivate: [CanActivateApp]},
  {path: 'organization' ,  component: OrganizationProfileComponent, canActivate: [CanActivateApp]},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)]
})

export class AppRoutingModule {
}
