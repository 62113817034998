import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangePasswordDto, UserProfileDto } from '../../core/app.interfaces';
import { CurrentUserService } from '../../core/current-user.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormErrorStateMatcher } from '../../core/form-error-state-matcher';
import { MatSnackBar } from '@angular/material';
import { ProfileService } from '../../services/profile.service';
import { OrganizationService } from '../../services/organization.service';
var UserProfileComponent = /** @class */ (function () {
    function UserProfileComponent(formBuilder, userProfile, currentUserService, activatedRoute, router, translate, snackBar, organizationService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.userProfile = userProfile;
        this.currentUserService = currentUserService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.translate = translate;
        this.snackBar = snackBar;
        this.organizationService = organizationService;
        this.currentUser = {
            name: '',
            email: '',
            language: this.translate.currentLang,
            organization: ''
        };
        this.orgs = [];
        this.matcher = new FormErrorStateMatcher();
        this.changePassForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(4)]],
            confirmPassword: ['']
        }, { validator: this.checkPasswords });
        this.userProfileForm = this.formBuilder.group({
            name: [''],
            email: [''],
            language: [''],
            organization: ['']
        });
        this.translate.onLangChange.subscribe(function (event) {
            _this.selectedLanguage = event.lang;
        });
    }
    UserProfileComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.readProfile();
        this.organizationService.getOrganizations().subscribe(function (organizations) {
            _this.orgs = organizations;
        });
        this.isError = false;
        this.isErrorProfile = false;
    };
    UserProfileComponent.prototype.readProfile = function () {
        var _this = this;
        this.userProfile.getUserProfile().subscribe(function (user) {
            _this.currentUser = user;
            _this.selectedLanguage = user.language;
            _this.selectedOrganization = user.organization;
        }, function (error) {
            var errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.snackBar.open('User profile read error. ' + errorMsg, 'OK', { duration: 3000 });
            _this.isError = true;
            _this.isErrorProfile = true;
        });
    };
    UserProfileComponent.prototype.changeOrganization = function ($event) {
        this.selectedOrganization = $event.value;
    };
    UserProfileComponent.prototype.changeLenguage = function ($event) {
        this.selectedLanguage = $event.value;
    };
    UserProfileComponent.prototype.saveProfile = function () {
        var _this = this;
        if (!this.userProfileForm.valid) {
            return;
        }
        this.requestSaveUserProfile = true;
        this.userProfile.updateUserProfile({
            name: this.userProfileForm.controls.name.value || this.currentUser.name,
            email: this.userProfileForm.controls.email.value || this.currentUser.email,
            language: this.selectedLanguage,
            organization: this.selectedOrganization
        }).subscribe(function (result) {
            _this.translate.use(result.language);
            localStorage.setItem('locale', result.language);
            _this.requestSaveUserProfile = false;
            _this.snackBar.open('Profile save successful.', 'OK', { duration: 3000 });
        }, function (error) {
            _this.errorMsgProfile = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.isErrorProfile = true;
            _this.requestSaveUserProfile = false;
        });
    };
    UserProfileComponent.prototype.checkPasswords = function (group) {
        var pass = group.controls.password.value;
        var confirmPass = group.controls.confirmPassword.value;
        return pass === confirmPass ? null : { notSame: true };
    };
    UserProfileComponent.prototype.savePassword = function () {
        var _this = this;
        if (!this.changePassForm.valid) {
            return;
        }
        this.requestProcessing = true;
        this.userProfile.changePassword({
            password: this.changePassForm.controls.password.value,
            confirmPassword: this.changePassForm.controls.confirmPassword.value
        }).subscribe(function (result) {
            _this.currentUserService.set(result);
            _this.requestProcessing = false;
            _this.isError = false;
            _this.snackBar.open('Password save successful.', 'OK', { duration: 3000 });
            _this.changePassForm.reset();
        }, function (error) {
            _this.errorMsg = (error.error ? error.error['message'] : undefined) || error['message'] || 'Something wrong.';
            _this.isError = true;
            _this.requestProcessing = false;
        });
    };
    return UserProfileComponent;
}());
export { UserProfileComponent };
