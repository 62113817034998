var FileValidators = /** @class */ (function () {
    function FileValidators() {
    }
    /**
     * Function to control content of files
     *
     * @param control
     *
     * @returns
     */
    FileValidators.maxContentSize = function (bytes) {
        return function (control) {
            //console.log(typeof control, control);
            var size = control && control.value ? control.value.files.map(function (f) { return f.size; }).reduce(function (acc, i) { return acc + i; }, 0) : 0;
            var condition = bytes > size;
            return condition ? null : {
                maxContentSize: {
                    actualSize: size,
                    maxSize: bytes
                }
            };
        };
    };
    return FileValidators;
}());
export { FileValidators };
