import { ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material';
var EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
var BatchResetPasswordComponent = /** @class */ (function () {
    function BatchResetPasswordComponent(authService, _router, snackBar) {
        this.authService = authService;
        this._router = _router;
        this.snackBar = snackBar;
        this.$isError = false;
        this.entity = {};
        this.emailFormControl = new FormControl('', [
            Validators.required,
            Validators.pattern(EMAIL_REGEX)
        ]);
    }
    BatchResetPasswordComponent.prototype.submit = function () {
        var _this = this;
        this.$isError = false;
        if (!this.editForm.valid) {
            return;
        }
        this.requestProcessing = true;
        this.authService.forgotPassword({
            email: this.entity.email,
            app: this.getCurrentURL()
        }).subscribe(function (result) {
            _this.requestProcessing = false;
            _this.snackBar.open('An e-mail has been sent to ' + _this.entity.email + ' with further instructions.', 'OK', { duration: 5000 });
            _this.cancel();
        }, function (error) {
            _this.errorMsg = error.status === 401 ? error.error['message'] : 'Something wrong.';
            _this.$isError = true;
            _this.requestProcessing = false;
            _this._router.navigate(['reset-password']);
        }, function () {
            _this.requestProcessing = false;
        });
    };
    BatchResetPasswordComponent.prototype.getCurrentURL = function () {
        return window.location.origin;
    };
    BatchResetPasswordComponent.prototype.cancel = function () {
        this._router.navigate(['login']);
    };
    return BatchResetPasswordComponent;
}());
export { BatchResetPasswordComponent };
