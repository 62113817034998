import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
var AuthService = /** @class */ (function () {
    function AuthService(http) {
        this.http = http;
        this.currentUser = new EventEmitter();
    }
    AuthService.prototype.login = function (body) {
        return this.http.post(environment.apiUrl + "/auth/login", body);
    };
    AuthService.prototype.changePassword = function (body) {
        return this.http.post(environment.apiUrl + "/auth/password", body);
    };
    AuthService.prototype.forgotPassword = function (body) {
        return this.http.post(environment.apiUrl + "/auth/forgot", body);
    };
    AuthService.prototype.getResetPassword = function (token) {
        return this.http.get(environment.apiUrl + "/auth/reset/" + token.token);
    };
    AuthService.prototype.postResetPassword = function (token, body) {
        return this.http.post(environment.apiUrl + "/auth/reset/" + token.token, body);
    };
    AuthService.prototype.logout = function () {
        return this.http.post(environment.apiUrl + "/auth/logout", null);
    };
    return AuthService;
}());
export { AuthService };
