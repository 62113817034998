import { Component, OnInit } from '@angular/core';
import {TemplateDto, UserDto} from "../core/app.interfaces";
import {BatchesService} from "../services/batches.service";
import {CurrentUserService} from "../core/current-user.service";
import {PurchaseService} from "../services/purchase.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import * as _ from 'lodash';
import {NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS, MatDialog} from "@angular/material";

import {AppDateAdapter, APP_DATE_FORMATS} from "./date-adapter";
import {SuccessDialogComponent} from "./success-dialog/success-dialog.component";
import {ANNResponseDto} from "../core/app.interfaces";
import {NewCertificateService} from "../services/new-certificate.service";

@Component({
  selector: 'app-new-certificate',
  templateUrl: './new-certificate.component.html',
  styleUrls: ['./new-certificate.component.scss'],
  providers: [{provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}]
})
export class NewCertificateComponent implements OnInit {

  templates = [];
  currentUser: UserDto;
  selectedTemplate: TemplateDto = {} as any;
  defaultLanguage = 'en';
  defaultCurrency = 'usd';
  state = false;
  fields = [];
  addPurchaseForm: FormGroup;
  show = false;
  isLoadingResults = true;
  isLoadingFields = false;
  isLoadingResponse = false;
  sLockMail=true;

  constructor(private currentUserService: CurrentUserService,
              private batchesService: BatchesService,
              private newCertService: NewCertificateService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog ) {
    this.addPurchaseForm = this.formBuilder.group({
      'sName': ['', [Validators.required]],
      'eMail': ['', [Validators.required, Validators.email]],
      'price': [''],
      'sLockMail': [this.sLockMail],
      'sLang': [this.defaultLanguage],
      'currency': [this.defaultCurrency],
      'templateId': [''],
      'issuedAt': [''],
      'name': [''],
      'criteria': [''],
      'expiresAt': ['']
    });
  }

  ngOnInit() {
    this.animateToTop();

    this.batchesService.getTemplateList().subscribe((list: {templates: TemplateDto[]}) => {
      this.templates = list.templates;
      this.isLoadingResults = false;
      this.currentUser = this.currentUserService.get();
      this.addPurchaseForm.patchValue({
        eMail: this.currentUser.email,
        sName: this.currentUser.name
      });
    }, (error) => {
      this.isLoadingResults = false;
      this.batchesService.showSnackBar('Error loading templates!');
    });

  }

  onChoseTemplate(template){
    this.isLoadingFields=true;
    if (template._id !== this.selectedTemplate._id){
      this.selectedTemplate = template;
      this.newCertService.getTemplate(template._id).subscribe(
        (result: {templates: TemplateDto}) => {
          this.fields = _.unionBy(result.templates.fields, 'name');
          this.fields.forEach(field => {
            this.addPurchaseForm.addControl(field.name, new FormControl(''))
          });
          this.addPurchaseForm.patchValue({templateId: template._id});
          this.isLoadingFields=false;
          this.show = true;
        }, (error) => {
          this.isLoadingFields=false;
          this.batchesService.showSnackBar('Error loading template!');
        }
      );
    } else {
      this.isLoadingFields=false;
    }
  }

  newBatch(value: object)
  {
    if (this.addPurchaseForm.valid){
      value['sLockMail'] = !this.sLockMail;
      value['price'] = value['price'].toString();
      this.isLoadingResponse = true;
      this.newCertService.newPurchase(value).subscribe((result: ANNResponseDto) => {
        this.isLoadingResponse = false;
        if (result.certificate){
          this.openDialog(result);
        } else {
          this.batchesService.showSnackBar('Connection failed');
        }
        // this.addPurchaseForm.reset();
      }, (error) => {
        this.batchesService.showSnackBar('Error creating purchase');
      });
      this.animateToTop();
    } else {
      this.isLoadingResponse = false;
      this.batchesService.showSnackBar('Please fill out all required fields!');
    }
  }

  openDialog(response: ANNResponseDto) {
    const dialogRef = this.dialog.open(SuccessDialogComponent, {
      width: '500px',
      autoFocus: false,
      data: response
    });
  }

  animateToTop(){
    let scrollToTop = window.setInterval(function() {
      let pos = window.pageYOffset;
      if ( pos > 0 ) {
        window.scrollTo( 0, pos - 20 );
      } else {
        window.clearInterval( scrollToTop );
      }
    }, 16);
  }
}
