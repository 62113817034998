import { AfterViewInit, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { merge } from 'rxjs/observable/merge';
import { of as observableOf } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators/catchError';
import { map } from 'rxjs/operators/map';
import { startWith } from 'rxjs/operators/startWith';
import { switchMap } from 'rxjs/operators/switchMap';
import { BatchesService } from '../services/batches.service';
import { FileValidators } from '../input-file/file-validators';
import 'rxjs/add/operator/toPromise';
import { CurrentUserService } from "../core/current-user.service";
import * as _ from 'lodash';
import { DeleteDialogComponent } from "./delete-dialog/delete-dialog.component";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { TranslateService } from '@ngx-translate/core';
var observedTimeout = 2000;
var BatchesComponent = /** @class */ (function () {
    function BatchesComponent(batchesService, formBuilder, currentUserService, dialog, route, location, translate) {
        var _this = this;
        this.batchesService = batchesService;
        this.formBuilder = formBuilder;
        this.currentUserService = currentUserService;
        this.dialog = dialog;
        this.route = route;
        this.location = location;
        this.translate = translate;
        this.displayedColumns = ['registration', 'eMail', 'templateId', 'iCount', 'rCount', 'eCount', 'state', 'pCount', 'delete'];
        this.resultsLength = 0;
        this.isLoadingResults = true;
        this.isRateLimitReached = false;
        this.dataSource = new MatTableDataSource();
        this.observedBatches = [];
        this.disabled = true;
        this.templates = [];
        this.selectedTemplate = null;
        this.show = false;
        this.c_sort = 'registration';
        this.c_order = 'desc';
        this.pageSize = 10;
        this.addBatchForm = this.formBuilder.group({
            'templateId': [{ value: '', disabled: true }, [Validators.required]],
            'eMail': ['', [Validators.required, Validators.email]],
            'fileInput': [{
                    value: undefined,
                    disabled: false
                }, [Validators.required, FileValidators.maxContentSize(100 * 1024 * 1024)]]
        });
        translate.onLangChange.subscribe(function (event) {
            translate.get('batches.form.template.file-defined').subscribe(function (value) {
                _this.selectedTemplate === null ? _this.addBatchForm.patchValue({ templateId: value }) : null;
            });
            // TODO: add paginator labels translation on air
            // this.translate.get('batches.table.paginator.items-per-page-label').subscribe((res: string) => {
            //   this.paginator._intl.itemsPerPageLabel=res;
            // });
            // this.translate.get('batches.table.paginator.next-page-label').subscribe((res: string) => {
            //   this.paginator._intl.nextPageLabel=res;
            // });
            // this.translate.get('batches.table.paginator.previous-page-label').subscribe((res: string) => {
            //   this.paginator._intl.previousPageLabel=res;
            // });
        });
    }
    BatchesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.queryParams.subscribe(function (params) {
            if (params.sort) {
                _this.c_sort = params.sort;
                _this.c_order = params.order;
                _this.pageSize = params.limit;
                _this.paginator.pageIndex = params.page;
            }
        });
        this.addBatchForm.patchValue({ eMail: this.currentUserService.get().email });
        this.batchesService.getTemplateList().subscribe(function (list) {
            _this.templates = list.templates;
        });
        this.location.replaceState('/batches');
    };
    BatchesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        // If the user changes the sort order, reset back to the first page.
        this.sort.sortChange.subscribe(function () {
            _this.paginator.pageIndex = 0;
            _this.c_sort = _this.sort.active;
            _this.c_order = _this.sort.direction;
        });
        merge(this.sort.sortChange, this.paginator.page)
            .pipe(startWith({}), switchMap(function () {
            _this.isLoadingResults = true;
            return _this.batchesService.getBatches((_this.c_sort === 'delete' ? 'pCount' : _this.c_sort), _this.c_order, _this.paginator.pageSize, _this.paginator.pageIndex);
        }), map(function (data) {
            // Flip flag to show that loading has finished.
            _this.isLoadingResults = false;
            _this.isRateLimitReached = false;
            _this.resultsLength = data.total_count;
            data.items.filter(function (b) { return b.state > 0 && b.state < 100; }).forEach(function (item) { return _this.observedBatches.push(item.id); });
            return data.items;
        }), catchError(function () {
            _this.isLoadingResults = false;
            // Catch if the GitHub API has reached its rate limit. Return empty data.
            _this.isRateLimitReached = true;
            return observableOf([]);
        })).subscribe(function (data) {
            // console.log(data);
            _this.dataSource.data = data;
        });
        setTimeout(function () { return _this.updateObserved(); }, observedTimeout);
    };
    BatchesComponent.prototype.addBatch = function (batch) {
        var _this = this;
        // batch.file=this.batchFile;
        // delete batch.fileInput;
        if (this.selectedTemplate) {
            batch.templateId = this.selectedTemplate._id;
        }
        // console.log(this.addBatchForm);
        // batch.eMail === "" ? this.addBatchForm.eMail.value
        this.batchesService.addBatchMultipart(batch, this.batchFile).subscribe(function (newBatch) {
            _this.addBatchForm.controls['fileInput'].reset();
            _this.observedBatches.push(newBatch.id);
            _this.ngAfterViewInit(); // this.dataSource.data.push(newBatch); this.resultsLength++;
        }, function (response) {
            if (response.status === 500) {
                console.log('addBatch: errorHasOcurred');
            }
        });
    };
    BatchesComponent.prototype.updateObserved = function () {
        var _this = this;
        if (this.observedBatches.length) {
            this.batchesService.getObserved(this.observedBatches).subscribe(function (batches) {
                batches.forEach(function (item) {
                    if (item.state < 0 || item.state >= 100) {
                        var i = _this.observedBatches.indexOf(item.id, 0);
                        if (i > -1) {
                            _this.observedBatches.splice(i, 1);
                            _this.batchesService.showSnackBar('Batch processing finished');
                        }
                    }
                    _this.dataSource.data.filter(function (b) { return b.id === item.id; }).forEach(function (b) {
                        b.state = item.state;
                        b.stateUpdated = item.stateUpdated;
                        b.errorText = item.errorText;
                        b.pCount = item.pCount;
                        b.rCount = item.rCount;
                        b.eCount = item.eCount;
                    });
                });
                setTimeout(function () { return _this.updateObserved(); }, observedTimeout);
            }, function (response) {
                if (response.status === 500) {
                    console.log('updateObserved: errorHasOcurred');
                }
            });
        }
        else {
            setTimeout(function () { return _this.updateObserved(); }, observedTimeout);
        }
    };
    BatchesComponent.prototype.openFile = function (event) {
        var files = event.target.files;
        this.batchFile = (files && files.length) ? files[0] : undefined;
    };
    BatchesComponent.prototype.isLoaded = function () {
        return this.batchFile !== undefined;
    };
    BatchesComponent.prototype.downloadFile = function (id, params) {
        this.batchesService.downloadFile(id, params);
    };
    BatchesComponent.prototype.pushBatch = function (id) {
        this.batchesService.pushBatch(id);
        this.observedBatches.push(id);
        this.ngAfterViewInit(); // this.dataSource.data.push(newBatch); this.resultsLength++;
    };
    BatchesComponent.prototype.pushVisible = function (row) {
        var now = new Date();
        var diff = (now.getTime() - (new Date(row.stateUpdated).getTime())) / 1000.0;
        return row.state < 0 || ((row.state < 100 || row.errorText.length > 0) && diff > 30.0);
    };
    BatchesComponent.prototype.onFileDefined = function (option) {
        var _this = this;
        if (option) {
            this.disabled = false;
            this.addBatchForm.patchValue({ templateId: option.name });
            this.selectedTemplate = option;
        }
        else {
            this.disabled = true;
            this.selectedTemplate = null;
            this.addBatchForm.controls['templateId'].disable();
            this.translate.get('batches.form.template.file-defined').subscribe(function (value) {
                _this.addBatchForm.patchValue({ templateId: value });
            });
        }
    };
    BatchesComponent.prototype.onManual = function () {
        this.addBatchForm.controls['templateId'].setValue('');
        this.addBatchForm.controls['templateId'].enable();
    };
    BatchesComponent.prototype.deleteBatch = function (batch) {
        var _this = this;
        this.batchesService.deleteBatch(batch.id).subscribe(function (result) {
            _this.dataSource.data = _.remove(_this.dataSource.data, function (value) {
                return value.id != batch.id;
            });
            _this.batchesService.showSnackBar('Batch has been deleted');
        }, function (error) {
            _this.batchesService.showSnackBar(error.error.message);
        });
    };
    BatchesComponent.prototype.openDeleteDialog = function (batch) {
        var _this = this;
        var dialogRef = this.dialog.open(DeleteDialogComponent, {
            width: '250px',
            autoFocus: false
        }).afterClosed().subscribe(function (result) {
            result ? _this.deleteBatch(batch) : null;
        });
    };
    return BatchesComponent;
}());
export { BatchesComponent };
