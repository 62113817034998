import {NativeDateAdapter} from "@angular/material";

export class AppDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
      const str = value.split('/');
      const year = Number(str[0]);
      const month = Number(str[1]) - 1;
      const day = Number(str[2]);
      const date = new Date(year, month, day);
      const offset = date.getTimezoneOffset();
      date.setMinutes(-1*offset);
      return date;
    }
    const timestamp = typeof value === 'number' ? value : Date.parse(value);
    return isNaN(timestamp) ? null : new Date(timestamp);
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat == "input") {
      const offset = date.getTimezoneOffset();
      date.setMinutes(-1*offset); // Hack to overcome timezone shift
      return date.getFullYear() + '-' + this._to2digit(date.getMonth() + 1) + '-' + this._to2digit(date.getDate()) ;
    } else {
      return date.toDateString();
    }
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}

export const APP_DATE_FORMATS =
  {
    parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    },
    display: {
      dateInput: 'input',
      monthYearLabel: { month: 'short', year: 'numeric', day: 'numeric' },
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
    }
  };
