<div class="add-padding flex flex-direction-row align-items-center justify-center container">
  <mat-card class="reset-form-container">
    <mat-card-title>{{'reset-password.title' | translate}}</mat-card-title>
    <!-- <mat-card-subtitle>If you don't have credentials, login as guest</mat-card-subtitle> -->
    <mat-card-content>
      <form #editForm="ngForm" class="reset-pass-form" (keydown.enter)="submit()" autocomplete="off">
        <mat-form-field>
          <input matInput #email type="email" name="email" [(ngModel)]="entity.email"
                 placeholder="{{'reset-password.email-placeholder' | translate}}" [formControl]="emailFormControl"
                 maxlength="100" autocomplete="off" required>
          <mat-error *ngIf="emailFormControl.hasError('pattern')">
            {{'reset-password.email-validation-error' | translate}}
          </mat-error>
        </mat-form-field>
        <div [hidden]="!$isError">
          <mat-error #errorInfo class="error">
            {{errorMsg}}
          </mat-error>
        </div>
      </form>
      <div class="btn-group">
        <button class="btn-submit" mat-raised-button type="submit" (click)="submit()"
                [disabled]="requestProcessing">{{'reset-password.submit-button' | translate}}</button>
      </div>
      <div class="label-link">
        <p><a routerLink="/login">{{'reset-password.auth-link' | translate}}</a></p>
      </div>
    </mat-card-content>
  </mat-card>
</div>

