import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class NewCertificateService {

  constructor(private http: HttpClient) { }

  newPurchase(form: object) {
    return this.http.post(`${environment.apiUrl}/batches/generate`, JSON.stringify(form), httpOptions);
  }

  getTemplate(id: string) {
    return this.http.get(`${environment.apiUrl}/templates/get/${id}`);
  }
}
