import { OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
var SuccessDialogComponent = /** @class */ (function () {
    function SuccessDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    SuccessDialogComponent.prototype.ngOnInit = function () {
    };
    SuccessDialogComponent.prototype.closeDialog = function () {
        this.dialogRef.close(false);
    };
    return SuccessDialogComponent;
}());
export { SuccessDialogComponent };
